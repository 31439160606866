/**
 * The abstract super-type implementation of a page.
 */
export abstract class PageComponent {

  /**
   * Probably should return RouteSegment or anything to identify the route.
   * @returns {null} as for now, but should be implemented properly
   */
  public getReferrerData(): any {
    return null;
  }

}

/**
 * @author tatevik.marikyan
 * @author Vahagn Lazyan
 * @since 22/09/2017
 */
import {switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {
    AuthenticationService,
    UserData,
} from '@synisys/idm-authentication-client-js';
import {
    Access,
    AuthorizationService,
    CategoryPermissionRequestDataDto,
    CategoryPermissionValueDto,
    PermissionType,
    PermissionValueDto,
} from '@synisys/idm-authorization-client-js';
import {
    PreconditionCheck,
    StringTemplate,
} from '@synisys/idm-common-util-frontend';

import {DataService} from '../../api/service/data.service';
import {DePermissionService} from '../../api/service/de-permission.service';
import {DePermission, DePermissionDto} from '../model/de-permission.model';
import {Observable} from 'rxjs/Observable';
import {from} from 'rxjs/observable/from';
import {zip} from 'rxjs/observable/zip';
import {SearchParam} from '@synisys/idm-de-core-frontend';

@Injectable()
export class HttpDePermissionService implements DePermissionService {
    private readonly DE_SERVICE_URI_KEY: string = 'de-service-url';

    /*tslint:disable:max-line-length*/

    private readonly URL_ENTITY_PERMISSIONS_BY_INSTANCE_ID: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/permission/${'systemName'}/instance/${'entityInstanceId'}/${'userId'}`;
    private readonly URL_ENTITIES_PERMISSIONS_BY_INSTANCE_IDS: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entities/permission/${'systemName'}/${'userId'}`;

    /*tslint:enable:max-line-length*/

    constructor(
        private readonly _applicationPropertiesService: ApplicationPropertiesService,
        private readonly _authenticationService: AuthenticationService,
        private readonly _authorizationService: AuthorizationService,
        private readonly _dataService: DataService
    ) {}

    /**
     * Get deserialized de permission for specified category by Entity Instance Id
     * @param {string} systemName - category system name.
     * @param {number} entityInstanceId
     * @returns {Observable<DePermission>}
     */
    public getCategoryDePermissionsByInstance(
        systemName: string,
        entityInstanceId: number
    ): Observable<DePermission> {
        const dePermissionObservable = this.loadDePermissions(
            systemName,
            entityInstanceId
        );

        return this.deserializeDePermissions(dePermissionObservable);
    }

    public getCategoryDePermissionsByInstances(
        systemName: string,
        entitiesInstanceIds: number[]
    ): Observable<DePermission[]> {
        const dePermissionsObservable = this.loadDePermissionsBulk(
            systemName,
            entitiesInstanceIds
        );

        return this.deserializeDePermissionsBulk(dePermissionsObservable);
    }

    /**
     * Checking Create / Add permission by Category
     * @param {string} systemName
     * @returns {Observable<boolean>}
     */
    public hasCreatePermissionByCategory(
        systemName: string
    ): Observable<boolean> {
        return this.getPermissionValue(systemName, PermissionType.ADD).pipe(
            map((categoryPermissionValues: Set<CategoryPermissionValueDto>) => {
                if (categoryPermissionValues.size > 0) {
                    return Array.from(categoryPermissionValues).some(
                        (
                            categoryPermissionValue: CategoryPermissionValueDto
                        ) => {
                            return (
                                categoryPermissionValue.access !== Access.DENIED
                            );
                        }
                    );
                } else {
                    return false;
                }
            })
        );
    }

    /**
     * Indicates if has access to specified Category Type
     * @param {string} systemName - category system name
     * @param {PermissionType} permissionType - requested permission type
     * @param {number} [workflowStateId] workflow state id
     * @returns {Observable<boolean>}
     * * @deprecated Use instead getCategoryDePermissionsByInstance
     */
    public hasAccessByPermissionType(
        systemName: string,
        permissionType: PermissionType,
        workflowStateId: number = null
    ): Observable<boolean> {
        return this.getPermissionValue(
            systemName,
            permissionType,
            workflowStateId
        ).pipe(
            map((permissions: Set<CategoryPermissionValueDto>) => {
                return Array.from(permissions).some(
                    (permission: CategoryPermissionValueDto) => {
                        return permission.access !== Access.DENIED;
                    }
                );
            })
        );
    }

    /**
     * Gets the Permission based on Category Permission Type and User Data
     * @param {string} systemName
     * @param {PermissionType} permissionType
     * @param {number} workflowStateId  workflow state id
     * @returns {Observable<PermissionValueDto>}
     */
    public getPermissionValue(
        systemName: string,
        permissionType: PermissionType,
        workflowStateId?: number
    ): Observable<Set<CategoryPermissionValueDto>> {
        const userData$: Observable<UserData> = this._authenticationService.getUserData();

        return userData$.pipe(
            switchMap((userData: UserData) => {
                const categoryPermissionRequestDataDto: CategoryPermissionRequestDataDto = {
                    categorySystemNames: [systemName],
                    conditionalPermissionValues: {},
                    permissionType,
                    userId: userData.userId,
                    workflowStateId: null,
                } as CategoryPermissionRequestDataDto;

                return this._authorizationService
                    .getCategoryPermissionsValues(
                        categoryPermissionRequestDataDto
                    )
                    .pipe(
                        map(
                            (
                                permissions: Map<
                                    string,
                                    Set<CategoryPermissionValueDto>
                                >
                            ) => {
                                return permissions.get(systemName);
                            }
                        )
                    );
            })
        );
    }

    /**
     * Loads de permission for specified category by Entity Instance Id
     * @param {string} systemName
     * @param {number} entityInstanceId
     * @returns {Observable<any>}
     */
    private loadDePermissions(
        systemName: string,
        entityInstanceId: number
    ): Observable<DePermissions> {
        PreconditionCheck.notNullOrUndefined(systemName);
        PreconditionCheck.notNullOrUndefined(entityInstanceId);

        const applicationProperties$ = from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        );
        const userData$ = this._authenticationService.getUserData();

        return zip<UserData, string>(userData$, applicationProperties$).pipe(
            switchMap(data => {
                const userData: UserData = data[0];
                const serviceUri: string = data[1];

                const url: string = this.URL_ENTITY_PERMISSIONS_BY_INSTANCE_ID.replaceTemplate(
                    {
                        entityInstanceId: (entityInstanceId || '')
                            .toString()
                            .split('?')[0],
                        serviceUri,
                        systemName,
                        userId: userData.userId.toString(),
                    }
                );

                return this._dataService.load(url);
            })
        );
    }

    private loadDePermissionsBulk(
        systemName: string,
        entityInstanceIds: number[]
    ): Observable<DePermissions[]> {
        PreconditionCheck.notNullOrUndefined(systemName);
        PreconditionCheck.notNullOrUndefined(entityInstanceIds);

        const applicationProperties$ = from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        );
        const userData$ = this._authenticationService.getUserData();
        let queryParams: SearchParam<any>[];

        return zip<UserData, string>(userData$, applicationProperties$).pipe(
            switchMap((data: [UserData, string]) => {
                const userData: UserData = data[0];
                const serviceUri: string = data[1];
                queryParams = [];
                queryParams.push(
                    new SearchParam('ids', entityInstanceIds.toString())
                );

                const url: string = this.URL_ENTITIES_PERMISSIONS_BY_INSTANCE_IDS.replaceTemplate(
                    {
                        serviceUri,
                        systemName,
                        userId: userData.userId.toString(),
                    }
                );

                return this._dataService.load(url, queryParams);
            })
        );
    }

    /**
     * Deserializes De Permissions
     * @param {Observable<any>} dePermissions$
     * @returns {Observable<DePermission>}
     */
    private deserializeDePermissions(
        dePermissions$: Observable<DePermissions>
    ): Observable<DePermission> {
        return dePermissions$.pipe(
            map((permissionData: DePermissions) => {
                const canCreate: boolean =
                    permissionData.add && permissionData.add.permission;
                const canDelete: boolean =
                    permissionData.delete && permissionData.delete.permission;

                return new DePermission(
                    canCreate,
                    canDelete,
                    permissionData.view,
                    permissionData.edit,
                    permissionData.add
                );
            })
        );
    }

    /**
     * Deserializes De Permissions
     * @param {Observable<any>} dePermissions$
     * @returns {Observable<DePermission>}
     */
    private deserializeDePermissionsBulk(
        dePermissions$: Observable<DePermissions[]>
    ): Observable<DePermission[]> {
        return dePermissions$.pipe(
            map((permissionsData: DePermissions[]) =>
                permissionsData.map(permissionData => {
                    const canCreate: boolean =
                        permissionData.add && permissionData.add.permission;
                    const canDelete: boolean =
                        permissionData.delete &&
                        permissionData.delete.permission;

                    return new DePermission(
                        canCreate,
                        canDelete,
                        permissionData.view,
                        permissionData.edit
                    );
                })
            )
        );
    }
}

interface DePermissions {
    view: DePermissionDto;
    edit: DePermissionDto;
    delete: DePermissionDto;
    add: DePermissionDto;
}

/**
 * Created by Mariam.Melkonyan on 11/14/2016.
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ControlsModule} from '@synisys/idm-ng-controls';

import {AuditTrailComponent} from './audit-trail/sis-audit-trail.component';
import {DeExportComponent} from './de-export/de-export.component';
import {DeHeadMenu} from './de-head-menu/de-head-menu.component';
import {DocumentItemComponent} from './document-row/sis-document-row.component';
import {EntitySearchComponent} from './entity-search/sis-entity-search.component';
import {MaterialModule} from './material.module';
import {MessageBoxPopup} from './message-box/sis-message-box-popup.component';
import {TranslateMessage} from './translate-message/translate-message.pipe';
import {WarningPageComponent} from './warning-page/warning-page.component';
import {TranslatePlaceholderMessagePipe} from './translate-message/translate-placeholder-message.pipe';

@NgModule({
  declarations: [
    AuditTrailComponent,
    DocumentItemComponent,
    DeExportComponent,
    TranslateMessage,
    MessageBoxPopup,
    WarningPageComponent,
    DeHeadMenu,
    EntitySearchComponent,
    TranslatePlaceholderMessagePipe
  ],
  exports: [
    AuditTrailComponent,
    DocumentItemComponent,
    DeExportComponent,
    TranslateMessage,
    MessageBoxPopup,
    WarningPageComponent,
    DeHeadMenu,
    EntitySearchComponent,
    TranslatePlaceholderMessagePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
})
export class DeControlsModule {
}

/**
 * Created by Anushavan.Hovsepyan
 * on 10/6/2016.
 */
import {Meta} from '../../impl/model/meta.model';

/**
 * The interface which describes the basic structure of aa response data from server.
 * @interface
 */
export abstract class ServiceResponse<T> {
    /**
     * Get the meta of a response.
     * @returns {Meta} The meta value.
     */
    public abstract getMeta(): Meta;

    /**
     * Get the data of a response.
     * @returns {T} The array of entities.
     */
    public abstract getData(): T;
}

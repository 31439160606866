import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {from} from 'rxjs/observable/from';

@Pipe({
        name: 'sisTranslateMessage',
        pure: true,
      })
export class TranslateMessage implements PipeTransform {

  public constructor(private ref: ChangeDetectorRef,
                     private messageService: MessageService) {
  }

  // From PipeTransform interface
  public transform(key: string): Observable<string> {
    // nothing to translate
    if (!key || key.trim().length === 0) {
      return from(['']);
    }
    key = key.trim();
    return this.messageService.getMessage(key);
  }

}

import {map, switchMap} from 'rxjs/operators';

import {
    Classifier,
    ClassifierService,
    ClassifierView,
} from '@synisys/idm-classifier-service-client-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    KbService,
    MetaField,
    MetaFieldId,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';

import {DeService} from '../shared/api/service';
import {MainEntity} from '../shared/impl/model';
import {PageComponent} from './page.component';
import {NotificationStatus} from '../shared/utilities';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {zip} from 'rxjs/observable/zip';
import {of} from 'rxjs/observable/of';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';

/**
 * Form component, which extends PageAbstractComponent class.
 * It contains common properties and methods for edit components.
 */
export abstract class FormComponent extends PageComponent {
    protected get systemName(): string {
        return this._systemName;
    }

    protected get mainEntity(): MainEntity {
        return this._mainEntity;
    }

    protected set mainEntity(value: MainEntity) {
        this._mainEntity = value;
    }

    protected get oldMainEntity(): MainEntity {
        return this._oldMainEntity;
    }

    protected set oldMainEntity(value: MainEntity) {
        this._oldMainEntity = value;
    }

    protected get classifierItems(): Map<string, ClassifierView[]> {
        return this._classifierItems;
    }

    protected set classifierItems(
        classifierItems: Map<string, ClassifierView[]>
    ) {
        this._classifierItems = classifierItems;
    }

    protected get hasWorkflow(): boolean {
        return this._hasWorkflow;
    }

    protected get languages(): Language[] {
        return this._languages;
    }

    protected get currentLanguageId(): number {
        return this._currentLanguageId;
    }

    protected get validations(): Validation[] {
        return this._validations;
    }

    protected get workflowStateFieldName(): string {
        return this._workflowStateFieldName;
    }

    protected get notificationStatus(): Subject<NotificationStatus> {
        return this._notificationStatus;
    }

    protected set notificationStatus(value: Subject<NotificationStatus>) {
        this._notificationStatus = value;
    }

    /**
     * This flag shows if the page is ready for rendering.
     * To be ready it is necessary to preload all the used Classifier Lists, Current language and Case Model.
     * @type {boolean}
     */
    public isReady = false;

    /**
     * Array of project languages.
     * @type {Array<Language>}
     * @private
     */
    protected _languages: Language[] = null;

    /**
     * Selected language ID.
     * @type {number}
     * @private
     */
    protected _currentLanguageId: number = null;

    /**
     * Validations for model. This validations are filled when the model is sent to server for saving.
     * @type {Array}
     * @private
     */
    protected _validations: Validation[] = [];

    /**
     * Component system name
     * @type {string}
     * @private
     */
    protected abstract _systemName: string = null;

    /**
     * Component model.
     * @type {MainEntity}
     * @protected
     */
    protected _mainEntity: MainEntity = null;

    /**
     * Component old model.
     * @type {MainEntity}
     * @protected
     */
    protected _oldMainEntity: MainEntity = null;

    /**
     * Workflow state system name.
     * @type {string}
     * @protected
     */
    protected _workflowStateFieldName: string = null;

    /**
     * Subject for storing notification status
     */
    protected _notificationStatus: Subject<NotificationStatus> = new Subject();

    /**
     * Indicator to understand if mainEntity has Workflow or not
     */
    private _hasWorkflow: boolean;

    /**
     * Map, which key is classifier name, value is list of classifier.
     * @type {Map<string, Array<Classifier>>}
     * @private
     */
    private _classifierItems: Map<string, ClassifierView[]>;

    /**
     * ID of new Entity.
     * @type {number}
     * @private
     */
    private newEntityId = -1;

    /**
     * The default constructor.
     */
    constructor(
        protected _deService: DeService,
        protected _classifierService: ClassifierService,
        protected _kbService: KbService,
        protected _languageService: LanguageService,
        protected _currentLanguageProvider: CurrentLanguageProvider
    ) {
        super();
        this._classifierItems = new Map<string, any>();
    }

    public getValidation(fieldSystemName: string): Validation {
        for (const validation of this._validations) {
            const relatedFields: MetaFieldId[] = validation.getRelatedMetaFieldIds();
            for (const relatedMetaFieldId of relatedFields) {
                if (relatedMetaFieldId.getSystemName() === fieldSystemName) {
                    return validation;
                }
            }
        }
        return null;
    }

    /**
     * Navigate to edit page.
     */
    protected abstract navigateToEditPage(): void;

    /**
     * Must not be overridden in inherited classes
     * Is responsible for workflow load after it existence checking
     */
    protected abstract onWorkflowExistenceLoaded(): Observable<any>;

    /**
     * Loads mainEntity with the given Instance Id.
     * @param {number} instanceId - InstanceId of the saved mainEntity.
     * @protected
     */
    protected loadMainEntity(instanceId: number): Observable<any> {
        return this._deService
            .loadEntityByInstanceId(this._systemName, instanceId)
            .pipe(
                switchMap((mainEntity: MainEntity) => {
                    this._mainEntity = mainEntity;
                    this._oldMainEntity = this._mainEntity.clone();
                    return this.onMainEntityLoaded();
                })
            );
    }

    /**
     * Preparation after MainEntity load
     * @returns {Observable<any>}
     */
    protected onMainEntityLoaded(): Observable<any> {
        return zip(this._loadClassifiers(), this.loadWorkflowExistence());
    }

    /**
     * Classifiers Load for used Classifiers in mainEntity and zipping
     * @returns {Observable<R>|Observable<T[]>}
     * @private
     */
    protected _loadClassifiers(): Observable<any> {
        const formClassifiers: Map<string, string> = this.getFormClassifiers();
        if (formClassifiers !== null) {
            const observables: Observable<any>[] = [];
            formClassifiers.forEach((value: string, key: string) => {
                observables.push(this._loadClassifier(key, value));
            });

            return zip(...observables);
        } else {
            return of([]);
        }
    }

    /**
     * Classifier Name Mapping by system names as Key and local Name as Value
     * @returns {Map<string, string>}
     */
    protected getFormClassifiers(): Map<string, string> {
        return null;
    }

    /**
     * Loads classifier to entity's classifierItems property.
     * @param {string} systemName - Category name of the required classifier.
     * @param {string} classifierKey - Classifier key of the entity.
     * @param {number} parentId - The parentId to filter entries by.
     */
    protected _loadClassifier(
        systemName: string,
        classifierKey: string,
        parentId?: number
    ): Observable<ClassifierView[]> {
        if (parentId !== null && parentId !== undefined) {
            return this._classifierService
                .loadClassifiersViewByParent(systemName, parentId)
                .pipe(
                    map((_classifiersData: ClassifierView[]) => {
                        this._classifierItems.set(
                            classifierKey,
                            _classifiersData
                        );
                        return _classifiersData;
                    })
                );
        } else {
            return this._classifierService.loadClassifiersView(systemName).pipe(
                map((_classifiersData: ClassifierView[]) => {
                    this._classifierItems.set(classifierKey, _classifiersData);
                    return _classifiersData;
                })
            );
        }
    }

    /**
     * Returns classifier by selected classifier id.
     * @param {number} classifierId - Classifier id, which is selected.
     * @param {string} classifierKey - Classifier key of the household.
     * @returns {Classifier}
     */
    protected getClassifierById(
        classifierId: number,
        classifierKey: string
    ): ClassifierView {
        const classifiers: ClassifierView[] = this._classifierItems.get(
            classifierKey
        );

        return classifiers.find((classifier: ClassifierView) => {
            return classifier.getId() === classifierId;
        });
    }

    /**
     * Gives the successive negative ID per Entity.
     * @returns {number}
     */
    protected getNewEntityId(): number {
        return this.newEntityId--;
    }

    /**
     * Method for checking the availability of Workflow for the mainEntity (main category)
     * @returns {boolean}
     */
    private loadWorkflowExistence(): Observable<any> {
        return this._kbService.getMetaFields(this._systemName).pipe(
            switchMap((metaFields: MetaField[]) => {
                this._hasWorkflow = false;
                for (const metaField of metaFields) {
                    if (metaField.getType() === MetaFieldType.WORKFLOW_STATE) {
                        this._hasWorkflow = true;
                        break;
                    }
                }
                return this.onWorkflowExistenceLoaded();
            })
        );
    }
}

import {Component, EventEmitter} from '@angular/core';

import {Entity} from '@synisys/idm-de-core-frontend';
import './sis-document-row.component.scss';

/**
 *  Document item view control,with deleting and downloading possibilities.
 *  value:value which should pass using sis-document-row tag,
 *   @value is document(Classifier object which must  have 'fileName' property)
 *  remove-event on deleting document item
 *  onDownload- event on clicking  document item for downloading
 *  viewingValues- values passed by client, which should view on UI
 *  f.e. viewingValues=[document.getProperty('title').value,document.getProperty('uploadDate').value]...
 */
@Component({
             inputs     : ['id', 'value', 'viewingValues', 'isRemovable', 'currentLanguageId'],
             moduleId   : module.id + '',
             outputs    : ['remove', 'download'],
             selector   : 'sis-document-row',
             templateUrl: 'sis-document-row.component.html',
           })
export class DocumentItemComponent {

  private static getExtensionOfFile(fileName: string): string {
    return fileName.split('.').pop();
  }

  public id: string;
  public value: Entity;
  public viewingValues: any[] = [];
  public isRemovable = true;
  private remove: EventEmitter<Entity> = new EventEmitter<Entity>();
  private download: EventEmitter<Entity> = new EventEmitter<Entity>();
  private currentLanguageId: number;

  public onRemove(removedValue: Entity): void {
    this.remove.emit(removedValue);
  }

  public onDownload(clickedValue: Entity): void {
    this.download.emit(clickedValue);
  }

  // TODO: maybe fileType enum sarqenq
  public getFileExtension(): string {
    const fileName: string = this.value.getProperty<string>('DOCUMENTNAME').value;
    return DocumentItemComponent.getExtensionOfFile(fileName);
  }

}

import {EntityDefault} from '@synisys/idm-de-core-frontend';

/**
 * An implementation of {@link EntityDefault} class. It is just a consumer type serving as a basic, default
 * implementation without any significant added value.
 * @class
 * @extends {EntityDefault}
 */
export class MainEntity extends EntityDefault {
    /**
     * @override
     * @returns {MainEntity}
     */
    public clone<T = MainEntity>(): T {
        return this.cloneEntity(MainEntity);
    }
}

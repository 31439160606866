/**
 * @author mariam.melkonyan
 * @since 2/16/17.
 */
import {catchError, first, map, switchMap, takeUntil} from 'rxjs/operators';
import {HostListener, OnDestroy} from '@angular/core';

import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {EntityComparisonHelper} from '@synisys/idm-de-core-frontend/app/shared/impl/helper/entity-comparison-helper';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {LockingService} from '@synisys/idm-lock-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';

import {ServiceResponse} from '../shared/api/model/service-response';
import {DeService} from '../shared/api/service/de.service';
import {MainEntity} from '../shared/impl/model/main-entity.model';
import {NotificationStatus} from '../shared/utilities/notification-status';
import {FormComponent} from './form.component';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {of} from 'rxjs/observable/of';
import {from} from 'rxjs/observable/from';
import {empty} from 'rxjs/observable/empty';
import {_throw as throwError} from 'rxjs/observable/throw';
import {noop} from 'rxjs/util/noop';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';

export abstract class EditFormComponent extends FormComponent
    implements OnDestroy {
    /**
     * "EntityName" field systemName, which is showing in monitoring screen.
     * In KB configs the type of this systemName field must be string.
     * @type {string}
     */
    protected abstract _lockField: string = null;

    /**
     * Helper for comparing 2 entities according to its meta fields.
     */
    private _entityComparisonHelper: EntityComparisonHelper;
    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(
        protected _deService: DeService,
        protected _classifierService: ClassifierService,
        protected _kbService: KbService,
        protected _languageService: LanguageService,
        protected _currentLanguageProvider: CurrentLanguageProvider,
        protected _lockingService: LockingService
    ) {
        super(
            _deService,
            _classifierService,
            _kbService,
            _languageService,
            _currentLanguageProvider
        );
        this._entityComparisonHelper = new EntityComparisonHelper(
            this._kbService
        );
    }

    public ngOnDestroy() {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    /**
     * Navigate to view page.
     */
    protected abstract navigateToViewPage(): void;

    /**
     * Loads and Initializes MainEntity
     * @param {number} instanceId
     */
    protected loadEditFormData(instanceId: number): void {
        let mainEntity$: Observable<any>;
        if (isNaN(instanceId)) {
            mainEntity$ = this._deService
                .createBlankEntity(this._systemName)
                .pipe(
                    first(),
                    switchMap((value: MainEntity) => {
                        this._mainEntity = value;
                        return this.onMainEntityLoaded();
                    })
                );
        } else {
            mainEntity$ = this.loadMainEntity(instanceId).pipe(first());
        }
        const languages$: Observable<any> = this._languageService.getInputLanguages();
        const currentLanguage$: Observable<Language> = this._currentLanguageProvider.getCurrentLanguage();
        combineLatest(mainEntity$, languages$, currentLanguage$)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                data => {
                    const languages: Language[] = data[1];
                    const language: Language = data[2];
                    this._languages = languages;
                    this._currentLanguageId = language.getId();
                    this.isReady = true;
                },
                err => console.error(err)
            );
    }

    /**
     * Remove mainEntity lock and navigate to view page.
     * This function also calls when window close/unload.
     */
    protected unlock(): void {
        this._lockingService
            .removeLock(this._systemName, this._mainEntity.getInstanceId())
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (data: any) => {
                    this.navigateToViewPage();
                },
                err => console.error(err)
            );
    }

    /**
     * Remove mainEntity lock.
     */
    @HostListener('window:popstate')
    @HostListener('window:beforeunload')
    protected removeLock(): void {
        if (this._mainEntity.getId() !== null && this._mainEntity.getId() > 0) {
            this._lockingService
                .removeLock(this._systemName, this._mainEntity.getInstanceId())
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    (data: any) => {
                        console.warn('Lock removed.');
                    },
                    err => console.error(err)
                );
        }
    }

    /**
     * Add lock for mainEntity.
     */
    protected addLock(): void {
        this._lockingService
            .addLock(
                this._systemName,
                this._mainEntity.getInstanceId(),
                this._mainEntity.getProperty<string>(this._lockField).value
            )
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(undefined, err => console.log(err));
    }

    protected onCancel(): void {
        if (this._mainEntity.getId() !== null && this._mainEntity.getId() > 0) {
            this.unlock();
        }
    }

    /**
     * Saves after comparing old data with modified data.
     */
    protected onSave(): void {
        const areEqual$: Observable<boolean> = this.compareEntities();

        areEqual$
            .pipe(
                switchMap((areEqual: boolean) => {
                    if (!areEqual) {
                        console.log('Saving...');
                        return this.save();
                    } else {
                        this._validations = [];
                        this._notificationStatus.next(
                            NotificationStatus.NO_MODIFICATION
                        );
                        return empty<ServiceResponse<MainEntity>>();
                    }
                }),
                map((serviceResponse: ServiceResponse<MainEntity>) => {
                    this.processServiceResponse(serviceResponse);
                    if (this._validations.length === 0) {
                        this.onSaveDone();
                    }
                }),
                catchError(err => {
                    console.error(err);
                    return empty<void>();
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(noop, console.error);
    }

    /**
     * After Save done preparation
     */
    protected onSaveDone(): void {
        this.addLock();
        this._oldMainEntity = this.mainEntity.clone();
    }

    /**
     * Saves after comparing old data with modified data.
     */
    protected onSaveAndClose(): void {
        const areEqual: Observable<boolean> = this.compareEntities();

        areEqual
            .pipe(
                switchMap((areEqual: boolean) => {
                    if (!areEqual) {
                        return this.save();
                    } else {
                        this._validations = [];
                        this._notificationStatus.next(
                            NotificationStatus.NO_MODIFICATION
                        );
                        this.unlock();
                        return empty<ServiceResponse<MainEntity>>();
                    }
                }),
                map((serviceResponse: ServiceResponse<MainEntity>) => {
                    this.processServiceResponse(serviceResponse);
                    if (this._validations.length === 0) {
                        this.onSaveAndCloseDone();
                    }
                }),
                catchError(err => {
                    console.error(err);
                    return empty<void>();
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(noop, console.error);
    }

    /**
     * After Save and Close done preparation
     */
    protected onSaveAndCloseDone(): void {
        this.unlock();
    }

    /**
     * Creates new mainEntity model or updates the existing one.
     * @returns {Observable<any>}
     */
    protected save(): Observable<any> {
        if (!!this._mainEntity.getId() && this._mainEntity.getId() !== -1) {
            return this.onBeforeSave().pipe(
                switchMap((mainEntity: MainEntity) => {
                    return this._deService
                        .update(this._systemName, this._mainEntity)
                        .pipe(catchError(this.handelErrorResponses));
                })
            );
        } else {
            return this.onBeforeSave().pipe(
                switchMap((mainEntity: MainEntity) => {
                    return this._deService.add(
                        this._systemName,
                        this._mainEntity
                    );
                })
            );
        }
    }

    /**
     * Preparation of Before Save logic
     * @returns {Observable<any>}
     */
    protected onBeforeSave(): Observable<any> {
        return of(null);
    }

    /**
     * Compares old and current entities.
     * @returns {Observable<boolean>}
     */
    protected compareEntities(): Observable<boolean> {
        if (this._oldMainEntity !== undefined && this._oldMainEntity !== null) {
            return from(
                this._entityComparisonHelper.equals(
                    this._systemName,
                    this._mainEntity,
                    this._oldMainEntity
                )
            );
        } else {
            return of(false);
        }
    }

    /**
     * * Preparation logic after workflow existence checking
     * @returns {Observable<any>}
     */
    protected onWorkflowExistenceLoaded(): Observable<any> {
        return of(null);
    }

    /**
     * Sets the selected value from component (combo, radio) to mainEntity's property
     * @param {any} event
     * @param {string} systemName - Category name of the classifier.
     * @param {string} classifierKey - Classifier key of the household.
     */
    protected onClassifierChanged(
        event: any,
        systemName: string,
        classifierKey: string
    ): void {
        const selectedClassifierId: number = +event.target.value;
        this._mainEntity.getProperty(systemName).value = !selectedClassifierId
            ? null
            : this.getClassifierById(selectedClassifierId, classifierKey);
    }

    /**
     * Processes Response from Service after MainEntity Save
     * @param {ServiceResponse<MainEntity>} serviceResponse
     */
    private processServiceResponse(
        serviceResponse: ServiceResponse<MainEntity>
    ): void {
        const validations: Validation[] = serviceResponse
            .getMeta()
            .getValidations();

        this._mainEntity = serviceResponse.getData();
        if (validations.length > 0) {
            this._validations = validations;
            this._notificationStatus.next(NotificationStatus.FAILURE);
        } else {
            this._validations = [];
            this._notificationStatus.next(NotificationStatus.SUCCESS);
            console.log('Saved with ID : ' + this._mainEntity.getId());
        }
    }

    private handelErrorResponses(err: any): Observable<any> {
        switch (err.status) {
            case 404: {
                this._notificationStatus.next(
                    NotificationStatus.ENTITY_NOT_FOUND
                );
                break;
            }
            case 423: {
                this._notificationStatus.next(NotificationStatus.ENTITY_LOCKED);
                break;
            }
            case 502: {
                this._notificationStatus.next(
                    NotificationStatus.SERVICE_UNAVAILABLE
                );
                break;
            }
        }
        return throwError(err);
    }
}

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Params,
    Router,
} from '@angular/router';

import {Observable} from 'rxjs/Observable';

import {DePermissionService} from '../api/service';
import {DePermission} from '../impl/model';
import {CategoryPermissionType} from '@synisys/idm-um-permission-client-js';
import {PermissionType} from '@synisys/idm-authorization-client-js';

@Injectable()
export class DePermissionGuard implements CanActivate {
    constructor(
        protected _dePermissionService: DePermissionService,
        protected _router: Router
    ) {}

    /**
     * DE Permission Guard for checking DE permission and navigating to error page if has no given permission
     * depending on category Permission Type and module's System Name
     * 1. ADD
     * 2. EDIT
     * 3. VIEW
     * 4. DELETE
     *
     * @param {ActivatedRouteSnapshot} route
     * @returns {Observable<boolean>}
     */
    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const systemName: string = route.data.systemName;
        const categoryPermissionType = route.data.categoryPermissionType;
        const permissionType = route.data.permissionType;
        let categoryPermissionName = route.data.categoryPermissionName;
        const permissionErrorPage = route.data.permissionErrorPage;
        const queryParams: Params = route.data.queryParams;
        const entityInstanceId = +route.params['instanceId'];

        if (entityInstanceId) {
            return this._dePermissionService
                .getCategoryDePermissionsByInstance(
                    systemName,
                    entityInstanceId
                )
                .pipe(
                    map((dePermission: DePermission) => {
                        const permission: PermissionType = this.convertPermission(
                            categoryPermissionType,
                            permissionType
                        );
                        const hasPermission = this.hasPermission(
                            permission,
                            dePermission
                        );

                        if (!hasPermission) {
                            if (!categoryPermissionName) {
                                categoryPermissionName = route.url[0].path;
                            }
                            if (!queryParams) {
                                this._router.navigateByUrl(
                                    `${permissionErrorPage}/${categoryPermissionName}`
                                );
                            } else {
                                this._router.navigate(
                                    [
                                        `${permissionErrorPage}/${categoryPermissionName}`,
                                    ],
                                    {queryParams}
                                );
                            }
                        }
                        return hasPermission;
                    })
                );
        }

        return this._dePermissionService
            .hasCreatePermissionByCategory(systemName)
            .pipe(
                map((hasPermission: boolean) => {
                    if (!hasPermission) {
                        if (!categoryPermissionName) {
                            categoryPermissionName = route.url[0].path;
                        }
                        if (!queryParams) {
                            this._router.navigateByUrl(
                                `${permissionErrorPage}/${categoryPermissionName}`
                            );
                        } else {
                            this._router.navigate(
                                [
                                    `${permissionErrorPage}/${categoryPermissionName}`,
                                ],
                                {queryParams}
                            );
                        }
                    }
                    return hasPermission;
                })
            );
    }

    private convertPermission(
        categoryPermissionType: CategoryPermissionType,
        permissionType: PermissionType
    ): PermissionType {
        if (permissionType === undefined) {
            switch (categoryPermissionType) {
                case CategoryPermissionType.ADD: {
                    return PermissionType.ADD;
                }
                case CategoryPermissionType.VIEW: {
                    return PermissionType.VIEW;
                }
                case CategoryPermissionType.EDIT: {
                    return PermissionType.EDIT;
                }
                case CategoryPermissionType.DELETE: {
                    return PermissionType.DELETE;
                }
                default: {
                    throw new Error(
                        `Illegal permission type ${CategoryPermissionType[categoryPermissionType]} was provided.`
                    );
                }
            }
        } else {
            return permissionType;
        }
    }

    private hasPermission(
        permissionType: PermissionType,
        dePermission: DePermission
    ): boolean {
        switch (permissionType) {
            case PermissionType.ADD: {
                return dePermission.canCreate;
            }
            case PermissionType.VIEW: {
                return dePermission.canView;
            }
            case PermissionType.EDIT: {
                return dePermission.canEdit;
            }
            case PermissionType.DELETE: {
                return dePermission.canDelete;
            }
            default: {
                throw new Error(
                    `Illegal permission type ${PermissionType[permissionType]} was provided.`
                );
            }
        }
    }
}

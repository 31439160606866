/**
 * Created by Anushavan.Hovsepyan
 * on 10/6/2016.
 */
import {ServiceResponse} from '../../api/model/service-response';
import {Meta} from './meta.model';

export class ServiceResponseDefault<T> implements ServiceResponse<T> {
    public hasViewPermission: boolean;
    /**
     * The meta of a response.
     * @type {Meta}
     * @private
     */
    private _meta: Meta;

    /**
     * The data of a response.
     * @type {T}
     * @private
     */
    private _data: T;

    /**
     * @inheritDoc
     * @public
     * @override
     */
    public getMeta(): Meta {
        return this._meta;
    }

    /**
     * Set the meta of a response.
     * @param {Meta} meta - The meta of a response.
     * @public
     */
    public setMeta(meta: Meta): void {
        this._meta = meta;
    }

    /**
     * @inheritDoc
     * @public
     * @override
     */
    public getData(): T {
        return this._data;
    }

    /**
     * Set the data of a response.
     * @param {T} data - The data of a response.
     * @public
     */
    public setData(data: T): void {
        this._data = data;
    }
}

import {Pipe, PipeTransform} from '@angular/core';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {from} from 'rxjs/observable/from';
import {Observable} from 'rxjs/Observable';

@Pipe({
    name: 'sisTranslateWithPlaceholder',
    pure: true,
})
export class TranslatePlaceholderMessagePipe implements PipeTransform {
  public constructor(private mlService: MessageService) {}

  public transform(key: string, ...placeholders: string[]): Observable<string> {
    if (!key || key.trim().length === 0) {
      return from(['']);
    }
    key = key.trim();
    return this.mlService.getMessageWithPlaceholder(key, placeholders);
  }
}

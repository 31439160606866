/**
 * Created by Anushavan.Hovsepyan
 * Updated by Tatevik.Marikyan
 * on 10/4/2016.
 */
import {first, takeUntil} from 'rxjs/operators';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {HttpClientWrapper} from '@synisys/idm-authentication-client-js';
import {ClassifierView} from '@synisys/idm-classifier-service-client-js';
import {StringTemplate} from '@synisys/idm-common-util-frontend';
import {Entity, PagingOptions, SearchParam, SortingOptions, SortType} from '@synisys/idm-de-core-frontend';

import {ServiceResponse} from '../../shared/api/model';
import {DeService} from '../../shared/api/service';
import {AbstractDestructionSubject} from '../../shared/utilities/abstract-destruction-subject';
import './sis-audit-trail.component.scss';

/**
 * AuditTrailComponent component.
 * Gets as input:
 *      systemName - The unique identifier for entity.
 *      instanceId - An identity of the instance to be fetched.
 *      tableColumnFields - Object containing table column title and property name.
 *      pageLimit - The limit (or number of items) of data to be fetched.
 */
@Component({
             moduleId   : module.id + '',
             selector   : 'sis-audit-trail',
             templateUrl: 'sis-audit-trail.component.html',
           })
export class AuditTrailComponent extends AbstractDestructionSubject implements OnDestroy, OnChanges {

  /**
   * Unique id for UI testing
   * @type {string}
   */
  @Input() public id: string;

  /**
   * System name of main entity.
   * @type {string}
   */
  @Input() public systemName: string;

  /**
   * Identity id of entity.
   * @type {number}
   */
  @Input() public identityId: number;

  /**
   * Instance id of entity.
   * @type {number}
   */
  @Input() public instanceId: number;

  /**
   * Current Language Id
   * @type {number}
   */
  @Input() public currentLanguageId: number;

  /**
   * Category's (Main Entity's) Object with header's name and field's system name
   * @type {string}
   */
  @Input() public identitySystemName: string;

  /**
   * Workflow state's system name
   * @type {string}
   */
  @Input() public wfStateSystemName: string;

  /**
   * Page limit of history.
   * @type {number}
   */
  @Input() public pageLimit = 5;

  /**
   * Module name given by section-config from console
   * @type {string}
   */
  @Input() public formId: string;

  /**
   * Visualisation params for Audit trail
   * @type {string}
   */
  @Input() public renderOptions: any;

  /**
   * Ignored Params for Audit trail
   * @type {string}
   */
  @Input() public ignoredFields: any;

  /**
   * Warning popup message for Compare
   * @type {string}
   */
  @Input() public warningMessage: string;

  /**
   * warning popup title for compare
   * @type {string}
   */
  @Input() public warningTitle: string;

  /**
   * Compare Button message
   * @type {string}
   */
  @Input() public compareButtonMsg: string;

  /**
   * Audit trail Table Version Column message
   * @type {string}
   */
  @Input() public versionColumnMsg: string;

  /**
   * formatDate function given from client Side
   */
  @Input() public formatDateFunction: any;

  @ViewChild('warningPopup')
  public warningPopup: any;

  public DATE_UPDATED_SYSTEM_NAME = 'DateUpdated';

  public USER_UPDATED_SYSTEM_NAME = 'UpdatedUserID';

  private DE_SERVICE_URI_KEY = 'de-service-url';

  /*tslint:disable:max-line-length*/

  private URL_ENTITY_DIFF: StringTemplate =
    StringTemplate.createTemplate`${'response'}/entity/diff/${'systemName'}/${'oldVersion'}/${'newVersion'}?formId=${'formId'}&`;

  /*tslint:enable:max-line-length*/

  /**
   * Current page of table.
   * @type {number}
   * @private
   */
  private _currentPage = 0;

  /**
   * AuditTrailComponent versions total count.
   * @type {number}
   * @private
   */
  private _totalVersionsCount: number;

  /**
   * AuditTrailComponent pages total count.
   * @type {number}
   * @private
   */
  private _totalPagesCount: number;

  /**
   * AuditTrailComponent versions.
   * @type {Array<Entity>}
   * @private
   */
  private _versions: Entity[];

  /**
   * Array of selected history versions.
   * @type {Entity[]}
   * @private
   */
  private _selectedVersions: any[] = [];

  /**
   * Shows if the version selection checkboxes are enabled/disabled.
   * @type {boolean}
   * @private
   */
  private _isCheckboxesDisabled = false;

  /**
   * Warning popup activate flag
   * @type {boolean}
   * @private
   */
  private _isWarningPopupActive = false;

  constructor(private _deService: DeService,
              private _httpClient: HttpClientWrapper,
              private _applicationPropertiesService: ApplicationPropertiesService) {
    super();
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(currentPage: number) {
    this._currentPage = currentPage;
    const startIndex: number = this._currentPage * this.pageLimit;

    this._loadItems(new PagingOptions(startIndex, this.pageLimit));
  }

  get totalVersionsCount(): number {
    return this._totalVersionsCount;
  }

  get versions(): Entity[] {
    return this._versions;
  }

  get totalPagesCount(): number {
    return this._totalPagesCount;
  }

  get isCheckboxesDisabled(): boolean {
    return this._isCheckboxesDisabled;
  }

  get isWarningPopupActive(): boolean {
    return this._isWarningPopupActive;
  }

  /**
   * Checks the changes and calls corresponding method to handle the change
   * @param {SimpleChange} changes
   * @returns {void}
   * @public
   * @method
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.identityId) {
      this._loadItems(new PagingOptions(0, this.pageLimit),
                      new SortingOptions(this.DATE_UPDATED_SYSTEM_NAME, SortType.DESC));
    }
  }

  public setWarningPopupActive(isWarningPopupActive: boolean): void {
    if (this._isWarningPopupActive !== isWarningPopupActive) {
      this._isWarningPopupActive = isWarningPopupActive;
      if (this.warningPopup) {
        if (isWarningPopupActive) {
          this.warningPopup.open();
        } else {
          this.warningPopup.close();
        }
      }
    }
  }

  /**
   * Selects/deselects version and disables checkboxes if it is necessary.
   * @param {any} checkBox - Clicked checkbox element.
   * @param {Entity} item
   */
  public selectVersions(checkBox: any, item: Entity): void {
    if (checkBox.checked) {
      this._selectedVersions.push({id: item.getId(), value: item.getProperty(this.DATE_UPDATED_SYSTEM_NAME)});
      if (this._selectedVersions.length === 2) {
        this._isCheckboxesDisabled = true;
      }
    } else {
      this._selectedVersions = this._selectedVersions.filter(version => version.id !== item.getId());
      this._isCheckboxesDisabled = false;
    }
  }

  /**
   * Compares selected two versions. If selected versions count less than two shows the warning message.
   */
  public compareVersions(): void {
    if (this._selectedVersions.length === 2) {
      const windowName = 'Versions comparison';
      const params = 'height=700,width=1500';

      const oldVersion = this._selectedVersions[0].id > this._selectedVersions[1].id
                         ? this._selectedVersions[1] : this._selectedVersions[0];
      const newVersion = this._selectedVersions[0].id < this._selectedVersions[1].id
                         ? this._selectedVersions[1] : this._selectedVersions[0];

      this.renderOptions.oldHeader = this.formatDateFunction(oldVersion.value.value);
      this.renderOptions.newHeader = this.formatDateFunction(newVersion.value.value);

      let httpParams: HttpParams = new HttpParams();
      const queryParams: SearchParam<any>[] = [];

      queryParams.push(new SearchParam('languageId', this.currentLanguageId));
      queryParams.push(new SearchParam('renderOptions', this.renderOptions));

      if (this.ignoredFields) {
        queryParams.push(new SearchParam('ignoredFields', this.ignoredFields));
      }

      queryParams.forEach((searchParam: SearchParam<any>) => {
        const value: any = typeof searchParam.getValue() !== 'object'
                           ? searchParam.getValue() : JSON.stringify(searchParam.getValue());
        httpParams = httpParams.set(searchParam.getName(), value);
      });

      const requestOptions = {headers: null as HttpHeaders, params: httpParams};
      const comparisonWindow: Window = window.open('', windowName, params);

      this._applicationPropertiesService.getProperty(this.DE_SERVICE_URI_KEY)
          .then((response: string) => {
            const url: string = this.URL_ENTITY_DIFF.replaceTemplate(
              {
                formId    : this.formId,
                newVersion: newVersion.id.toString(),
                oldVersion: oldVersion.id.toString(),
                response,
                systemName: this.systemName,
              });

            this._httpClient.get(url, requestOptions)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe((entityDiffResponse: any) => {
                  const body: string = entityDiffResponse.replace(/\\/g, '');
                  comparisonWindow.document.open();
                  comparisonWindow.document.write(body);
                  comparisonWindow.document.close();
                }, err => console.error(err));
          });

    } else if (this._selectedVersions.length < 2) {
      this.setWarningPopupActive(true);
    } else {
      throw new Error('The selected versions can\'t be more than two.');
    }
  }

  public getUserFullName(user: ClassifierView): string {
    return user.getProperty<string>('FIRSTNAME').value + ' ' + user.getProperty<string>('LASTNAME').value;
  }

  public trackByFunc(index: number, entity: Entity) {
    return entity.getId();
  }

  /**
   * Checks if the item is selected or not from corresponding list.
   * @param {Entity} item - the item which we want to know is Selected or not .
   */
  protected isItemSelected(item: Entity): boolean {
    return this._selectedVersions.some((version: any) => {
      return version.id === item.getId();
    });
  }

  /**
   * Loads entity history.
   * @param {PagingOptions} pagingOptions - The pagingOptions to serve entity history in accordance.
   * @param {SortingOptions} sortingOptions - The sortingOptions to serve entity history in accordance.
   */
  private _loadItems(pagingOptions: PagingOptions, sortingOptions?: SortingOptions): void {
    if (!sortingOptions) {
      sortingOptions = new SortingOptions(this.DATE_UPDATED_SYSTEM_NAME, SortType.DESC);
    }
    const loadableMetaFields: string[] = [
      this.identitySystemName,
      this.DATE_UPDATED_SYSTEM_NAME,
      this.USER_UPDATED_SYSTEM_NAME,
      this.wfStateSystemName !== undefined && this.wfStateSystemName,
    ].filter(Boolean);

    this._deService
        .loadHistoricalEntityItems(this.systemName, this.instanceId, pagingOptions, sortingOptions, loadableMetaFields)
        .pipe(first())
        .subscribe((response: ServiceResponse<Entity[]>) => {
          this._versions = response.getData();
          this._totalVersionsCount = response.getMeta().getTotalRowCount();
          this._totalPagesCount = Math.ceil(this._totalVersionsCount / this.pageLimit);
          // this._sortVersions();
        }, err => console.error(err));
  }
}

import {debounceTime, map, switchMap, takeUntil} from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material';
import {
    FilterBuilder,
    FilterCriteria,
    PagingOptions,
} from '@synisys/idm-de-core-frontend';
import {
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {PortfolioService} from '../../shared/api/service';
import {EntitySearchItem} from './model';
import {MultifieldFilterCriteriaModel} from '@synisys/idm-de-core-frontend/app/shared/impl/helper/filter/multifield-filter-criteria.model';
import {ServiceResponse} from '../../shared/api/model';
import {ServiceResponseDefault} from '../../shared/impl/model';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {AbstractDestructionSubject} from '../../shared/utilities/abstract-destruction-subject';
import './sis-entity-search.component.scss';
import {CategoryPermissionType} from '@synisys/idm-um-permission-client-js';

@Component({
    moduleId: module.id + '',
    selector: 'sis-entity-search',
    templateUrl: 'sis-entity-search.component.html',
})
export class EntitySearchComponent extends AbstractDestructionSubject
    implements OnInit, OnDestroy, OnChanges {
    @Input('value')
    public set value(value: any) {
        this._value = value;
    }

    public get value() {
        return this._value;
    }

    @ViewChild('autoCompleteInput', {read: MatAutocompleteTrigger})
    public autoComplete: MatAutocompleteTrigger;

    @Input() public id: string;

    @Input() public titleKey: string;

    /**
     * Entity which will be filtered, e.g. Person.
     */
    @Input()
    public filterableEntity: string;

    /**
     * Entity fields (paths) which will be used to filter data, e.g. NID, BID..
     */
    @Input()
    public filterFields: string[];

    /**
     * Category Permission Types thatg data should be loaded with
     */
    @Input()
    public categoryPermissionTypes?: CategoryPermissionType[];

    /**
     * Transfer selected value.
     * @type {EventEmitter<any>}
     */
    @Output()
    public valueChange: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Observed Filtered Entities.
     */
    public filteredEntities: Observable<EntitySearchItem[] | Observable<any>>;

    /**
     * Entity Search Control.
     */
    public entitySearchCtrl: FormControl;

    /**
     * Selected Entity.
     */
    public selectedEntity: EntitySearchItem;

    /**
     * Load More Entity Serach Item
     * @type {EntitySearchItem}
     * @private
     */
    public loadMoreEntity: EntitySearchItem = new EntitySearchItem(
        -1,
        'loadMore'
    );

    /**
     * Filtered data
     * @type {any[]}
     * @private
     */
    public filteredData: EntitySearchItem[] = [];

    private DEFAULT_ROWS_COUNT = 2;

    /**
     * Nested mainEntity's Instance MetaField Name.
     */
    private nestedEntityInstanceName: string;

    /**
     * Filtered Datas' total rows count
     */
    private totalRows: number;

    /**
     * The value which transfers selected data.
     * @type {any}
     */
    private _value: any;

    /**
     * Rows to show after search.
     */
    private rowsToShow: number = this.DEFAULT_ROWS_COUNT;

    constructor(
        private portfolioService: PortfolioService,
        private kbService: KbService
    ) {
        super();
        this.entitySearchCtrl = new FormControl();
        this.onFilterValueChange();
    }

    public ngOnInit(): void {
        this.kbService
            .getMetaFields(this.filterableEntity)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (metaFields: MetaField[]) => {
                    this.checkingFilterFieldsTypes(metaFields);
                    this.initFilteredCategoryInstanceFieldName(metaFields);
                    this.initSelectedEntity();
                },
                err => console.log(err)
            );
    }

    public ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
        if (
            changes['filterableEntity'] &&
            changes['filterableEntity'].currentValue &&
            changes['filterableEntity'].previousValue
        ) {
            this.filterableEntity = changes['filterableEntity'].currentValue;
            this.kbService
                .getMetaFields(this.filterableEntity)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    (metaFields: MetaField[]) => {
                        this.initFilteredCategoryInstanceFieldName(metaFields);
                    },
                    err => console.log(err)
                );
        }
    }

    /**
     * Checking filtered fields types for given Filterable Entity's metaFields
     * @param {MetaField[]} metaFields
     */
    public checkingFilterFieldsTypes(metaFields: MetaField[]): void {
        this.filterFields.forEach((filterField: string) => {
            let isWrongFieldName = true;
            for (const metaField of metaFields) {
                if (
                    metaField.getSystemName() === filterField &&
                    metaField.getType() !== MetaFieldType.STRING &&
                    metaField.getType() !== MetaFieldType.MULTILINGUAL_STRING
                ) {
                    throw new Error(
                        `Given ${filterField} metaFiled's type is wrong. MetaFields' type must be either STRING or MULTILINGUAL_STRING`
                    );
                } else if (metaField.getSystemName() === filterField) {
                    isWrongFieldName = false;
                    break;
                }
            }
            if (isWrongFieldName) {
                throw new Error(
                    `Given ${filterField} metaFiled's systemName is wrong.'`
                );
            }
        });
    }

    public onEntitySelected(event: any): void {
        if (event.source.selected) {
            this.selectedEntity = event.source.value;
            this.entitySearchCtrl.setValue(event.source.value.displayName, {
                emitEvent: false,
            });

            this.rowsToShow = this.DEFAULT_ROWS_COUNT;
            this.valueChange.emit((<EntitySearchItem>this.selectedEntity).id);
        }
    }

    public onLoadMoreSelected(event: any): void {
        if (event.source.selected) {
            this.rowsToShow += this.DEFAULT_ROWS_COUNT;

            const displayName =
                this.entitySearchCtrl.value instanceof EntitySearchItem
                    ? this.entitySearchCtrl.value.displayName
                    : this.entitySearchCtrl.value;
            this.loadMoreEntity.displayName = displayName;
            this.entitySearchCtrl.setValue(displayName, {emitEvent: true});
        }
    }

    /**
     * Indicates when to show "Load more" option
     * @returns {boolean}
     */
    public showLoadMoreOption(): boolean {
        return this.totalRows > this.rowsToShow;
    }

    public isEmptyValue(): boolean {
        return (
            this.entitySearchCtrl.value === undefined ||
            this.entitySearchCtrl.value === null ||
            this.entitySearchCtrl.value === ''
        );
    }

    /**
     * Indicates when to show "No More available items"
     * @returns {boolean}
     */
    public showNoDataAvailable(): boolean {
        return (
            !this.isEmptyValue() &&
            this.selectedEntity === null &&
            this.filteredData.length === 0
        );
    }

    public clearEntitySearchCtrl(): void {
        this.selectedEntity = null;
        this.rowsToShow = this.DEFAULT_ROWS_COUNT;
        this.totalRows = 0;
        this.filteredData = [];
        this.entitySearchCtrl.setValue('');
        this.valueChange.emit(null);
    }

    public resetRowCount(): void {
        if (this.entitySearchCtrl.value === '') {
            this.rowsToShow = this.DEFAULT_ROWS_COUNT;
        }
    }

    public trackByFunc(index: number, entity: EntitySearchItem) {
        return entity.id;
    }

    private onFilterValueChange(): void {
        this.filteredEntities = this.entitySearchCtrl.valueChanges.pipe(
            debounceTime(500),
            switchMap((value: any) => {
                const searchValue =
                    typeof value === 'string' ? value : value.displayName;
                return value === '' || value === null
                    ? of(new ServiceResponseDefault())
                    : this.searchEntities(
                          searchValue,
                          new PagingOptions(0, this.rowsToShow)
                      );
            }),
            map((filteredData: ServiceResponseDefault<EntitySearchItem[]>) => {
                this.totalRows =
                    filteredData.getMeta() !== undefined
                        ? filteredData.getMeta().getTotalRowCount()
                        : 0;

                if (
                    !this.autoComplete.autocomplete._isOpen &&
                    !this.selectedEntity
                ) {
                    this.autoComplete.openPanel();
                }

                return (this.filteredData =
                    filteredData.getData() !== undefined
                        ? filteredData.getData()
                        : []);
            })
        );
    }

    private initSelectedEntity(): void {
        if (this._value !== undefined && this._value !== null) {
            const filterBuilder: FilterBuilder = new FilterBuilder();
            filterBuilder.is(this.nestedEntityInstanceName, this._value);

            this.portfolioService
                .loadEntitySearchItems(
                    this.filterableEntity,
                    this.filterFields,
                    this.nestedEntityInstanceName,
                    new PagingOptions(0, 1),
                    null,
                    filterBuilder.buildJson(),
                    this.categoryPermissionTypes
                )
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    (data: ServiceResponseDefault<EntitySearchItem[]>) => {
                        if (data.getMeta().getTotalRowCount() > 0) {
                            const searchedEntity = data.getData()[0];

                            const filterFiledData: string[] = this.filterFields.map(
                                (filterField: string) => {
                                    return searchedEntity.entity[filterField];
                                }
                            );

                            this.selectedEntity = new EntitySearchItem(
                                searchedEntity.entity[
                                    this.nestedEntityInstanceName
                                ],
                                filterFiledData.join(' | '),
                                searchedEntity.entity
                            );
                            this.entitySearchCtrl.setValue(
                                this.selectedEntity.displayName
                            );
                        }
                    },
                    err => console.log(err)
                );
        }
    }

    /**
     * Getting instance field name for given filterable Entity.
     * @param {MetaField[]} metaFields - Filterable Entity's metaFields
     */
    private initFilteredCategoryInstanceFieldName(
        metaFields: MetaField[]
    ): void {
        const integerInstanceMetaField = metaFields.find(
            (metaField: MetaField) =>
                metaField.getType() === MetaFieldType.INTEGER_INSTANCE
        );

        if (integerInstanceMetaField) {
            this.nestedEntityInstanceName = integerInstanceMetaField.getSystemName();
        } else {
            throw new Error(
                `Given ${this.filterableEntity} Category has no Instance Id`
            );
        }
    }

    /**
     * Creates FilterCriteria according to the selected filters.
     * This will be passed to Entity Search Service.
     * @returns {FilterCriteria}
     */
    private getMultiFieldFilterCriteria(
        searchableValue: string
    ): FilterCriteria {
        let mainFilterBuilder: FilterBuilder;

        if (this.filterFields !== undefined && this.filterFields !== null) {
            mainFilterBuilder = new FilterBuilder();
            searchableValue !== undefined &&
                searchableValue !== null &&
                searchableValue.trim() !== '' &&
                mainFilterBuilder.multifield(
                    new MultifieldFilterCriteriaModel(
                        searchableValue.trim(),
                        this.filterFields
                    )
                );
        }

        return mainFilterBuilder.build();
    }

    private searchEntities(
        searchableValue: string,
        pagingOptions: PagingOptions
    ): Observable<ServiceResponse<EntitySearchItem[]>> {
        return this.portfolioService.loadEntitySearchItems(
            this.filterableEntity,
            this.filterFields,
            this.nestedEntityInstanceName,
            pagingOptions,
            null,
            this.getMultiFieldFilterCriteria(searchableValue).toJson(),
            this.categoryPermissionTypes
        );
    }
}

/**
 * @author siranush.avetisyan
 * @since 10/03/17.
 */
import {takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy} from '@angular/core';
import {ExportService, ExportType} from '@synisys/idm-export-client-js';
import './de-export.component.scss';
import {AbstractDestructionSubject} from '../../shared/utilities/abstract-destruction-subject';

/**
 *  Export item control, with exporting and downloading possibilities.
 */
@Component({
             moduleId   : module.id + '',
             selector   : 'de-export',
             templateUrl: 'de-export.component.html',
           })
export class DeExportComponent extends AbstractDestructionSubject implements OnDestroy {

  public ExportType = ExportType;

  /**
   * Category System Name.
   *
   * @type {string}
   */
  @Input() public categorySystemName: string;

  /**
   * Category Item ID.
   *
   * @type {number}
   */
  @Input() public categoryItemId: number;

  /**
   * Current Language ID.
   *
   * @type {number}
   */
  @Input() public currentLanguageId: number;

  /**
   * Enabled Export Types.
   * Default value is [ExportType.PDF, ExportType.EXCEL, ExportType.RTF].
   *
   * @type {Array<ExportType>}
   */
  @Input() public enabledExportTypes: ExportType[] = [ExportType.PDF, ExportType.EXCEL, ExportType.RTF];

  /**
   * Layout Name where located template/css/image files.
   *
   * @type {string}
   */
  @Input() public layoutName: string;

  /**
   * Exported file name.
   * Default value is "export".
   *
   * @type {string}
   */
  @Input() public fileName = 'export';

  /**
   * Message Component Ids.
   * When we don't pass any value for this field then export service get all component messages.
   * If we don't pass any value then export service loads all component messages.
   *
   * @type {Array<number>}
   */
  @Input() public messageComponentIds: number[] = null;

  constructor(private _exportService: ExportService) {
    super();
  }

  public export(exportType: ExportType): void {
    this._exportService.export(this.categorySystemName, this.categoryItemId, this.currentLanguageId, exportType,
                               this.layoutName, this.fileName, this.messageComponentIds)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(() => {}, err => console.error(err));
  }

  public isEnabled(exportType: ExportType): boolean {
    return this.enabledExportTypes.indexOf(exportType) > -1;
  }
}

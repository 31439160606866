import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import {Observable} from 'rxjs/Observable';

import {SearchParam} from '@synisys/idm-de-core-frontend';

/**
 * A class-interface to define some commons of a service providing access to the data.
 * This is a class-interface (or restricting interface) - a special construct that is an abstract class but serves as an
 * interface to enforce some public members and hide others.
 * @class
 */
export abstract class DataService {
    public abstract load(
        url: string,
        searchParams?: SearchParam<any>[]
    ): Observable<any>;

    public abstract add(
        url: string,
        data: any,
        searchParams?: SearchParam<any>[]
    ): Observable<any>;

    public abstract update(
        url: string,
        data: any,
        searchParams?: SearchParam<any>[]
    ): Observable<any>;

    public abstract delete(
        url: string,
        searchParams?: SearchParam<any>[]
    ): Observable<any>;

    public abstract upload(url: string, file: File, data: any): Observable<any>;

    public abstract download(url: string): void;
}

import {catchError, first, map, switchMap, takeUntil} from 'rxjs/operators';

import {
    AuthenticationService,
    CookieService,
    UserData,
} from '@synisys/idm-authentication-client-js';
import {
    ClassifierService,
    ClassifierView,
} from '@synisys/idm-classifier-service-client-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {LockData, LockingService} from '@synisys/idm-lock-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {
    ActionDto,
    ActionService,
} from '@synisys/idm-workflow-service-client-js';

import {ServiceResponse} from '../shared/api/model';
import {DePermissionService, DeService} from '../shared/api/service';
import {DePermission, MainEntity} from '../shared/impl/model';
import {FormComponent} from './form.component';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {zip} from 'rxjs/observable/zip';
import {from} from 'rxjs/observable/from';
import {of} from 'rxjs/observable/of';
import {Subject} from 'rxjs/Subject';
import {OnDestroy} from '@angular/core';
import {empty} from 'rxjs/observable/empty';
import {noop} from 'rxjs/util/noop';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';

export abstract class ViewFormComponent extends FormComponent
    implements OnDestroy {
    get dePermission(): DePermission {
        return this._dePermission;
    }

    protected get workflowActions(): ActionDto[] {
        return this._workflowActions;
    }

    /**
     * Logged in User Id
     * @type {any}
     * @private
     */
    protected _currentUserId: number = null;

    /**
     * Workflow Actions Array
     */
    private _workflowActions: ActionDto[];

    private _dePermission: DePermission;

    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(
        protected _deService: DeService,
        protected _classifierService: ClassifierService,
        protected _kbService: KbService,
        protected _actionService: ActionService,
        protected _languageService: LanguageService,
        protected _currentLanguageProvider: CurrentLanguageProvider,
        protected _lockingService: LockingService,
        protected _cookieService: CookieService,
        protected _dePermissionService: DePermissionService,
        protected _authenticationService: AuthenticationService
    ) {
        super(
            _deService,
            _classifierService,
            _kbService,
            _languageService,
            _currentLanguageProvider
        );
    }

    public ngOnDestroy() {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    /**
     * Navigate to lock error page.
     */
    protected abstract navigateToLockErrorPage(data: LockData): void;

    /**
     * Loads and Initializes MainEntity
     * @param {number} instanceId - instanceId of mainEntity to load.
     */
    protected loadViewFormData(instanceId: number): void {
        const mainEntity$: Observable<any> = this.loadMainEntity(
            instanceId
        ).pipe(first());
        const currentLanguage$: Observable<Language> = this._currentLanguageProvider.getCurrentLanguage();
        const userData$: Observable<UserData> = this._authenticationService.getUserData();

        combineLatest(mainEntity$, currentLanguage$, userData$)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                data => {
                    const language: Language = data[1];
                    this._currentLanguageId = language.getId();
                    this._currentUserId = +data[2].userId;
                    this.isReady = true;
                },
                err => console.error(err)
            );
    }

    protected onMainEntityLoaded(): Observable<any> {
        return zip(super.onMainEntityLoaded(), this.getDePermissions());
    }

    protected edit(): void {
        this._lockingService
            .getLock(this._systemName, this._mainEntity.getInstanceId())
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (data: LockData) => {
                    if (
                        data.getIsLocked() &&
                        data.userId !== this._currentUserId
                    ) {
                        this.navigateToLockErrorPage(data);
                    } else {
                        this.navigateToEditPage();
                    }
                },
                err => console.error(err)
            );
    }

    /**
     * View form Delete functionality with permission checking
     */
    protected delete(): void {
        this._lockingService
            .getLock(this._systemName, this._mainEntity.getInstanceId())
            .pipe(
                switchMap((data: LockData) => {
                    if (
                        data.getIsLocked() &&
                        data.userId !== this._currentUserId
                    ) {
                        this.navigateToLockErrorPage(data);
                        return empty<void>();
                    } else {
                        return this._deService.delete(
                            this._systemName,
                            this._mainEntity
                        );
                    }
                }),
                map(() => {
                    this.onDeleteDone();
                }),
                catchError(err => {
                    console.error(err);
                    return empty<void>();
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(noop, console.error);
    }

    /**
     * After Deletion preparation logic
     */
    protected onDeleteDone(): void {
        this.navigateToDeletePage();
    }

    /**
     * Navigation to delete page.
     */
    protected abstract navigateToDeletePage(): void;

    /**
     * Preparation logic after workflow existence checking
     * @returns {Observable<any>}
     */
    protected onWorkflowExistenceLoaded(): Observable<any> {
        return this.getWorkflowActions();
    }

    /**
     * Do Workflow Action and changes the state correspondingly to action
     * @param {number} actionId - corresponding action which must be done
     */
    protected doWorkflowAction(actionId: number): void {
        if (this.hasWorkflow && actionId && actionId !== 0) {
            this._deService
                .doWorkflowAction(this._systemName, this._mainEntity, actionId)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    (serviceResponse: ServiceResponse<MainEntity>) => {
                        const validations: Validation[] = serviceResponse
                            .getMeta()
                            .getValidations();
                        if (validations.length > 0) {
                            this._validations = validations;
                        } else {
                            this._validations = [];
                            this.onWorkflowActionDone(serviceResponse);
                        }
                    },
                    err => console.error(err)
                );
        } else if (!this.hasWorkflow) {
            throw new Error(`${this._systemName} has no Workflow`);
        }
    }

    /**
     * After Workflow Action done preparations
     * @param {ServiceResponse<MainEntity>} serviceResponse
     */
    protected onWorkflowActionDone(
        serviceResponse: ServiceResponse<MainEntity>
    ): void {
        this._mainEntity = serviceResponse.getData();
        this.getWorkflowActions()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (workflowActions: ActionDto[]) => {
                    this._workflowActions = workflowActions;
                },
                error1 => console.error(error1)
            );
    }

    private _setCurrentLanguageId(): void {
        this._currentLanguageProvider
            .getCurrentLanguage()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (language: Language) => {
                    this._currentLanguageId = language.getId();
                },
                (error1: any) => {
                    console.error('An error occurred', error1);
                }
            );
    }

    /**
     * Loads The workflow Actions ' list of current Workflow State
     * @returns {Observable<any>}
     */
    private getWorkflowActions(): Observable<any> {
        if (this.hasWorkflow) {
            const actionPromise: Promise<ActionDto[]> = this._actionService.getActionsByStateId(
                this.mainEntity
                    .getProperty<ClassifierView>(this._workflowStateFieldName)
                    .value.getId()
            );

            return from(actionPromise).pipe(
                map((actions: any) => {
                    this._workflowActions = actions;

                    return this._workflowActions;
                })
            );
        } else {
            return of([]);
        }
    }

    /**
     * Gets de/details permissions based on user permissions from de-service
     */
    private getDePermissions(): Observable<DePermission> {
        return this._dePermissionService
            .getCategoryDePermissionsByInstance(
                this._systemName,
                this._mainEntity.getInstanceId()
            )
            .pipe(
                map((dePermission: DePermission) => {
                    this._dePermission = dePermission;
                    return dePermission;
                })
            );
    }
}

/**
 * @author siranush.avetisyan
 * @author Vahagn Lazyan
 * @since 7/14/17.
 */
export class DePermission {
    private readonly _viewPermissionDto: DePermissionDto;
    private readonly _editPermissionDto: DePermissionDto;
    private readonly _addPermissionDto: DePermissionDto;
    constructor(
        private readonly _canCreate?: boolean,
        private readonly _canDelete?: boolean,
        viewPermissionDto?: DePermissionDto,
        editPermissionDto?: DePermissionDto,
        addPermissionDto?: DePermissionDto
    ) {
        this._viewPermissionDto = viewPermissionDto || ({} as DePermissionDto);
        this._editPermissionDto = editPermissionDto || ({} as DePermissionDto);
        this._addPermissionDto = addPermissionDto || ({} as DePermissionDto);
    }

    get canView(): boolean {
        return this._viewPermissionDto.permission;
    }

    get canEdit(): boolean {
        return this._editPermissionDto.permission;
    }

    get canCreate(): boolean {
        return this._canCreate;
    }

    get canDelete(): boolean {
        return this._canDelete;
    }

    get viewableFields(): string[] {
        return this._viewPermissionDto.fields;
    }

    get editableFields(): string[] {
        return this._editPermissionDto.fields;
    }

    get addableFields(): string[] {
        return this._addPermissionDto.fields;
    }
}

/**
 * @since 5.0.0
 */
export interface DePermissionDto {
    readonly permission: boolean;
    readonly fields: string[];
}

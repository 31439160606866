import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {PageComponent} from '../../pages';
import './warning-page.component.scss';

@Component({
    moduleId: module.id + '',
    selector: 'sis-warning-page',
    templateUrl: 'warning-page.component.html',
})
export class WarningPageComponent extends PageComponent
    implements AfterViewInit {
    /**
     * To hide Ok button, if necessary.
     * @default
     * @type {boolean}
     * @since 3.1.0
     */
    @Input()
    public displayOkButton = true;

    /**
     * Message to interpret warning message.
     * @type {string}
     */
    @Input() public warningMessage: string = null;
    @Input() public userName = '';

    @Input() public buttonMessage = 'Ok';
    @Input() public fullScreen = true;
    @Output() public customOkFunction = new EventEmitter<any>();

    public isSisWarningPageInitialized: boolean;

    constructor(public cdRef: ChangeDetectorRef) {
        super();
    }

    public onOkClicked(): void {
        this.fullScreen ? window.close() : this.customOkFunction.emit();
    }

    public ngAfterViewInit(): void {
        this.isSisWarningPageInitialized = true;
        this.cdRef.detectChanges();
    }
}

/**
 * Created by david.shadunts on 11/7/2017.
 */
export enum NotificationStatus {
    FAILURE,
    SUCCESS,
    NO_MODIFICATION,
    ENTITY_LOCKED,
    ENTITY_NOT_FOUND,
    SERVICE_UNAVAILABLE,
    FORBIDDEN,
}

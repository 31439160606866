/**
 * Created by tatevik.marikyan on 6/30/17.
 */
import {Component, ElementRef, Input} from '@angular/core';

import {PopupWindow} from '@synisys/idm-ng-controls';
import './sis-message-box-popup.component.scss';

@Component({
             moduleId   : module.id + '',
             selector   : 'sis-message-box-popup',
             templateUrl: 'sis-message-box-popup.component.html',
           })
export class MessageBoxPopup extends PopupWindow {

  @Input() public message: string;

  constructor(private elementRef: ElementRef) {
    super(elementRef);
    this.width = 300;
    this.height = 'auto';
  }
}

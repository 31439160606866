/**
 * @author tatevik.marikyan
 * @since 13/03/2018
 */

import {Entity} from '@synisys/idm-de-core-frontend';
import {
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js/src/model/meta-field';
import {Meta} from '../impl/model/meta.model';
import {
    MultilingualString,
    MultilingualStringBuilder,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    Validation,
    ValidationMessageData,
    ValidationPriority,
    ValidationSeverity,
} from '@synisys/idm-validation-calculation-service-client-js';
import {
    MetaCategoryId,
    MetaFieldId,
    MetaGroupId,
} from '@synisys/idm-kb-service-client-js';
import {ValidationDisplay} from '@synisys/idm-validation-calculation-service-client-js/src/model/validation-display';
import {
    ValidationDisplay as DeValidationDisplay,
    ValidationError,
} from '../impl/model';
import {ValidationDisplayType} from '@synisys/idm-validation-calculation-service-client-js/src/model/validation-display-type';

/**
 * Deserializes the Meta object.
 * @param {any} responseMeta - JSON object containing the data of meta.
 * @returns {Meta} the object, which contains data of meta
 * @private
 */
export function deserializeMeta(responseMeta: any): Meta {
    const meta: Meta = new Meta();

    meta.setTotalRowCount(responseMeta.totalRowCount);
    meta.setValidations(createValidations(responseMeta.validationErrors));
    // TODO: Remove this along with createValidationErrors and _createValidationError on next major release
    meta.setValidationErrors(
        createValidationErrors(responseMeta.validationErrors)
    );

    return meta;
}

/**
 * Creates array of validation errors from response.
 * @param {Array<any>} responseValidationErrors - JSON object containing the array of validation errors.
 * @returns {Array<Validation>} array of Validation object
 * @private
 */
export function createValidations(
    responseValidationErrors: any[]
): Validation[] {
    const validationErrors: Validation[] = [];
    responseValidationErrors &&
        responseValidationErrors.forEach(responseValidationError => {
            validationErrors.push(_createValidation(responseValidationError));
        });
    return validationErrors;
}

/**
 * Creates array of validation errors from response.
 * @param {Array<any>} responseValidationErrors - JSON object containing the array of validation errors.
 * @returns {Array<ValidationError>} array of Validation object
 * @private
 */
export function createValidationErrors(
    responseValidationErrors: any[]
): ValidationError[] {
    const validationErrors: ValidationError[] = [];
    responseValidationErrors &&
        responseValidationErrors.forEach(responseValidationError => {
            validationErrors.push(
                _createValidationError(responseValidationError)
            );
        });
    return validationErrors;
}

/**
 * Creates an object, containing validation's information.
 * @param {any} responseValidationError - JSON object containing the data of validation error.
 * @returns {Array<Validation>} array of Validation objects
 * @private
 */
function _createValidation(
    responseValidationError: ResponseValidation
): Validation {
    return new Validation(
        messageToMultilingualString(responseValidationError.message),
        messageToMultilingualString(responseValidationError.messageDescription),
        ValidationSeverity[responseValidationError.validationSeverity],
        ValidationPriority[responseValidationError.validationPriority],
        responseValidationError.validatorLabel,
        responseValidationError.relatedMetaFieldIds.map(wrapperToMetaFieldId),
        responseValidationError.validationDisplay
            ? new ValidationDisplay(
                  ValidationDisplayType[
                      responseValidationError.validationDisplay.displayType
                  ],
                  new Map<string, any>([
                      [
                          'fields',
                          responseValidationError.validationDisplay.displayData,
                      ],
                  ])
              )
            : undefined,
        responseValidationError.messageData
            ? new ValidationMessageData(
                  messageToMultilingualString(
                      responseValidationError.messageData.message
                  ),
                  responseValidationError.messageData.data
              )
            : undefined,
        responseValidationError.messageDescriptionData
            ? new ValidationMessageData(
                  messageToMultilingualString(
                      responseValidationError.messageDescriptionData.message
                  ),
                  responseValidationError.messageDescriptionData.data
              )
            : undefined,
        responseValidationError.identityId
    );
}

/**
 * Creates object containing validation error information.
 * @param {any} responseValidationError - JSON object containing the data of validation error.
 * @returns {Array<ValidationError>} array of ValidationError object
 * @private
 */
function _createValidationError(
    responseValidationError: ResponseValidation
): ValidationError {
    const validationError: ValidationError = new ValidationError();
    const keys: string[] = Object.keys(responseValidationError.message.values);
    const multilingualStringBuilder: MultilingualStringBuilder = MultilingualString.newBuilder();
    keys.forEach((key: string) => {
        multilingualStringBuilder.withValueForLanguage(
            parseInt(key, 10),
            responseValidationError.message.values[key]
        );
    });
    validationError.setMessage(multilingualStringBuilder.build());
    validationError.identityId = responseValidationError.identityId;
    validationError.setRelatedFields(responseValidationError.relatedFields);
    validationError.validationDisplay = DeValidationDisplay.fromResponse(
        responseValidationError.validationDisplay
    );
    validationError.validatorLabel = responseValidationError.validatorLabel;
    return validationError;
}

function messageToMultilingualString(message: any): MultilingualString {
    const keys: string[] = Object.keys(message.values);
    const multilingualStringBuilder: MultilingualStringBuilder = MultilingualString.newBuilder();
    keys.forEach((key: string) => {
        multilingualStringBuilder.withValueForLanguage(
            parseInt(key, 10),
            message.values[key]
        );
    });
    return multilingualStringBuilder.build();
}

function wrapperToMetaFieldId(
    metaFieldIdWrapper: MetaFieldIdWrapper
): MetaFieldId {
    return new MetaFieldId(
        new MetaCategoryId(metaFieldIdWrapper.metaCategoryId.systemName),
        metaFieldIdWrapper.systemName,
        metaFieldIdWrapper.groupId
            ? new MetaGroupId(metaFieldIdWrapper.groupId.systemName)
            : undefined
    );
}

export function isCompoundField(field: MetaField): boolean {
    const fieldType = field.getType();
    return (
        fieldType === MetaFieldType.CLASSIFIER ||
        fieldType === MetaFieldType.MULTI_SELECT ||
        fieldType === MetaFieldType.MAIN_ENTITY ||
        fieldType === MetaFieldType.SUB_ENTITY ||
        fieldType === MetaFieldType.WORKFLOW_STATE ||
        fieldType === MetaFieldType.LOOKUP
    );
}

export function isFieldValueNotEmpty(
    field: MetaField,
    entity: Entity
): boolean {
    const fieldType: MetaFieldType = field.getType();
    const fieldName: string = field.getSystemName();
    if (
        fieldType === MetaFieldType.CLASSIFIER ||
        fieldType === MetaFieldType.MAIN_ENTITY ||
        fieldType === MetaFieldType.WORKFLOW_STATE ||
        fieldType === MetaFieldType.LOOKUP
    ) {
        return entity.getProperty(fieldName).value !== null;
    } else if (
        fieldType === MetaFieldType.MULTI_SELECT ||
        fieldType === MetaFieldType.SUB_ENTITY
    ) {
        return entity.getProperty<any[]>(fieldName).value.length > 0;
    }
}

interface ResponseValidation {
    identityId: number;
    message: any;
    messageDescription: any;
    messageData: {
        message: any;
        data: any;
    };
    messageDescriptionData: {
        message: any;
        data: any;
    };
    relatedFields: string[];
    relatedMetaFieldIds: MetaFieldIdWrapper[];
    validationDisplay: {
        displayType: string;
        displayData: string[];
    };
    validatorLabel: string;
    validationSeverity: string;
    validationPriority: string;
}

interface MetaFieldIdWrapper {
    metaCategoryId: {
        systemName: string;
    };
    systemName: string;
    groupId?: {
        systemName: string;
    };
}

/**
 * @author tatevik.marikyan
 * @since 5/16/17.
 */
import {
    Classifier,
    ClassifierView,
} from '@synisys/idm-classifier-service-client-js';

import {MainEntity} from '../impl/model/main-entity.model';
import {SubEntity} from '../impl/model/sub-entity.model';

/**
 * Provides UniqueId for newly created Sub Entities'
 * @param {MainEntity} mainEntity
 * @param {string} systemName
 * @returns {number}
 */
export function getUniqueIdForSubEntity(
    mainEntity: MainEntity,
    systemName: string
): number {
    const subEntityItems: any[] = mainEntity.getProperty<SubEntity[]>(
        systemName
    ).value;
    const subEntityIds: number[] = subEntityItems.map(
        (subEntity: SubEntity) => {
            return subEntity.getId();
        }
    );
    const subEntityIdMinValue: number =
        subEntityItems.length > 0 ? Math.min(...subEntityIds) : 0;
    return subEntityIdMinValue > 0 ? -1 : subEntityIdMinValue - 1;
}

/**
 * Adds/removes selected item to/from list of selected items and puts the value in MainEntity
 * @param {any} event
 * @param {MainEntity} mainEntity - mainEntity in which the value must be put
 * @param {string} systemName - changed Item system name
 * @param {Classifier} selectedItem
 */
export function onMultiSelectItemsChanged(
    event: any,
    mainEntity: MainEntity,
    systemName: string,
    selectedItem: ClassifierView
): void {
    const selectedItems: ClassifierView[] = mainEntity.getProperty<
        ClassifierView[]
    >(systemName).value;
    _processSelectedItems(event, selectedItems, selectedItem);
}

/**
 * Adds/removes selected item to/from list of selected items.
 * @param {any} event - Item change event.
 * @param {Array<ClassifierView>} selectedItems - The list of selected items.
 * @param {ClassifierView} selectedItem
 */
function _processSelectedItems(
    event: any,
    selectedItems: ClassifierView[],
    selectedItem: ClassifierView
): void {
    const isChecked = event.currentTarget.checked;
    if (isChecked) {
        selectedItems.push(selectedItem);
    } else {
        for (let i = 0; i < selectedItems.length; i++) {
            if (selectedItem.getId() === selectedItems[i].getId()) {
                selectedItems.splice(i, 1);
            }
        }
    }
}

export function todayUTC(): number {
    const today = new Date();
    return Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
}

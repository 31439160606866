export class EntitySearchItem {

  /**
   * Basic constructor that initializes the state.
   * @param {number} id
   * @param {string} displayName
   * @param {any} entity
   */
  public constructor(public id: number,
                     public displayName: string,
                     public entity?: any) {
  }
}

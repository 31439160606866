/**
 * @author tatevik.marikyan
 * @since 22/09/2017
 */
import {Observable} from 'rxjs/Observable';

import {DePermission} from '../../impl/model/de-permission.model';
import {
    CategoryPermissionValueDto,
    PermissionType,
} from '@synisys/idm-authorization-client-js';

export abstract class DePermissionService {
    /**
     * Get deserialized de permission for specified category by Entity Instance Id
     * @param {string} systemName
     * @param {number} entityInstanceId
     * @returns {Observable<DePermission>}
     */
    public abstract getCategoryDePermissionsByInstance(
        systemName: string,
        entityInstanceId: number
    ): Observable<DePermission>;

    /**
     * Get deserialized de permissions for specified category by all specified Entity Instance Ids
     * @param {string} systemName
     * @param {number} entitiesInstanceIds
     * @return {Observable<DePermission[]>}
     */
    public abstract getCategoryDePermissionsByInstances(
        systemName: string,
        entitiesInstanceIds: number[]
    ): Observable<DePermission[]>;

    /**
     * Checking Create / Add permission by Category
     * @param {string} systemName
     * @returns {Observable<boolean>}
     */
    public abstract hasCreatePermissionByCategory(
        systemName: string
    ): Observable<boolean>;

    /**
     * Indicates if has access to specified Category Type
     * @param {string} systemName - category system name
     * @param {PermissionType} permissionType - requested permission type
     * @param {number} [workflowStateId] workflow state id
     * @returns {Observable<boolean>}
     * @deprecated Use instead getCategoryDePermissionsByInstance
     */
    public abstract hasAccessByPermissionType(
        systemName: string,
        permissionType: PermissionType,
        workflowStateId?: number
    ): Observable<boolean>;

    /**
     * Gets the Permission based on Category Permission Type and User Data
     * @param {string} systemName
     * @param {PermissionType} permissionType
     * @param {number} workflowStateId  workflow state id
     * @returns {Observable<PermissionValueDto>}
     */
    public abstract getPermissionValue(
        systemName: string,
        permissionType: PermissionType,
        workflowStateId?: number
    ): Observable<Set<CategoryPermissionValueDto>>;
}

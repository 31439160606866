import {EntityDefault} from '@synisys/idm-de-core-frontend';

/**
 * An implementation of {@link EntityDefault} class. It is just a consumer type serving as a basic, default
 * implementation without any significant added value.
 * @class
 * @extends {EntityDefault}
 */
export class SubEntity extends EntityDefault {
    public setId(id: number) {
        this.getProperty<number>(this._identityField).value = id;
    }

    /**
     * @override
     * @returns {SubEntity}
     */
    public clone<T = SubEntity>(): T {
        return this.cloneEntity(SubEntity);
    }
}

/**
 * Created by mariam.melkonyan on 2/17/17.
 */
import {Observable} from 'rxjs/Observable';
import {StringTemplate} from '@synisys/idm-common-util-frontend';
import {
    Entity,
    PagingOptions,
    SortingOptions,
} from '@synisys/idm-de-core-frontend';
import {ValidationsCalculationsData} from '@synisys/idm-validation-calculation-service-client-js';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Meta, ResponseStatus} from '../../impl';
import {ServiceResponse} from '../model';
import {ActionDto} from '@synisys/idm-workflow-service-client-js';

export abstract class DeService {
    public URL_ENTITY_LOAD_ONE: StringTemplate;

    public URL_ENTITY_LOAD_MANY: StringTemplate;

    public URL_ENTITY_ADD: StringTemplate;

    public URL_ENTITY_UPDATE: StringTemplate;

    public URL_ENTITY_DELETE: StringTemplate;

    public URL_ENTITY_LOAD_HISTORICAL_ITEMS: StringTemplate;

    public URL_LOAD_ENTITY_BY_INSTANCE_ID: StringTemplate;

    public URL_CHECK_WF_ACTIONS_EXPRESSIONS: StringTemplate;

    public URL_DO_WORKFLOW_ACTION: StringTemplate;

    public URL_DO_BULK_WORKFLOW_ACTION: StringTemplate;

    /**
     * Create blank(empty) entity. This function is used when a new entity is created.
     * @param {string} categoryName - The unique identifier for entity.
     * @returns {Promise<Entity>}
     */
    public abstract createBlankEntity(categoryName: string): Observable<any>;

    public abstract createBlankSubEntity(categoryName: string): Observable<any>;

    public abstract loadEntity(
        categoryName: string,
        entityId: number,
        withTransientFields?: boolean
    ): Observable<any>;

    public abstract loadEntities(
        categoryName: string,
        paging?: PagingOptions,
        filterCriteria?: string
    ): Observable<any>;

    public abstract getEntitiesByIds(
        categoryName: string,
        instanceIds: number[],
        withTransientFields?: boolean
    ): Observable<Entity>;

    public abstract loadHistoricalEntityItems(
        categoryName: string,
        instanceId: number,
        pagingOptions: PagingOptions,
        sortingOptions?: SortingOptions,
        loadableFields?: string[]
    ): Observable<any>;

    public abstract loadEntityByInstanceId(
        categoryName: string,
        entityInstanceId: number,
        withTransientFields?: boolean,
        fieldSystemNames?: string[]
    ): Observable<any>;

    public abstract add(categoryName: string, entity: Entity): Observable<any>;

    public abstract bulkAdd(
        categoryName: string,
        entities: Entity[],
        raiseEvent?: boolean,
        enableCrossMasterValidations?: boolean,
        withOneTransaction?: boolean
    ): Observable<ServiceResponse<object>[]>;

    public abstract update(
        categoryName: string,
        entity: Entity
    ): Observable<any>;

    public abstract bulkUpdate(
        categoryName: string,
        entities: Entity[],
        raiseEvent?: boolean,
        enableCrossMasterValidations?: boolean,
        withOneTransaction?: boolean
    ): Observable<Map<number, Meta>>;

    public abstract delete(
        categoryName: string,
        entity: Entity
    ): Observable<any>;

    public abstract deleteById(
        categoryName: string,
        entityId: number
    ): Observable<any>;

    public abstract getPermittedActions(
        categoryName: string,
        entityId: number
    ): Observable<ActionDto[]>;

    public abstract doWorkflowAction(
        categoryName: string,
        entity: Entity,
        actionId: number
    ): Observable<any>;

    public abstract deleteByInstanceId(
        systemName: string,
        instanceId: number
    ): Observable<any>;

    public abstract doBulkWorkflowAction(
        categoryName: string,
        entityIds: number[],
        actionId: number,
        comment: MultilingualString
    ): Observable<Map<number, ResponseStatus>>;

    public abstract validateEntity(
        categoryName: string,
        entity: Entity
    ): Observable<any>;

    public abstract validateAndCalculateEntity(
        categoryName: string,
        entity: Entity
    ): Observable<ValidationsCalculationsData>;

    public abstract validateAndCalculateField(
        categoryName: string,
        fieldSystemName: string,
        entity: Entity
    ): Observable<ValidationsCalculationsData>;

    public abstract validateField(
        categoryName: string,
        fieldSystemName: string,
        entity: Entity
    ): Observable<any>;

    public abstract getConfig(
        categoryName: string,
        path: string,
        name: string
    ): Observable<any>;

    public abstract getConfigs(
        categoryName: string,
        prefix: string
    ): Observable<any>;

    public abstract getDocumentToken(
        categoryName: string,
        entityId: number,
        documentSystemName: string,
        permissionType?: string
    ): Observable<string>;

    public abstract getDocumentTokenByDocumentId(
        categoryName: string,
        documentId: number,
        permissionType?: string
    ): Observable<string>;

    public abstract getEntityName(
        categorySystemName: string,
        entity: Entity,
        separator: string
    ): Observable<string>;
}

/**
 * Created by Anushavan.Hovsepyan
 * on 10/14/2016.
 */
import {ValidationDisplay} from './validation-display.model';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';

export class ValidationError {
    /**
     * entity identifier.
     * @type {number}
     * @private
     */
    private _identityId: number;
    /**
     * Validation error message.
     * @type {string}
     * @private
     */
    private _message: MultilingualString;

    /**
     * Array of validation error related fields.
     * @type {Array<string>}
     * @private
     */
    private _relatedFields: string[];

    /**
     * Validation Label
     * @type {string}
     * @private
     */
    private _validatorLabel: string;

    /**
     * Validation Display
     * @type {ValidationDisplay}
     * @private
     */
    private _validationDisplay: ValidationDisplay;

    public get identityId(): number {
        return this._identityId;
    }

    public set identityId(value: number) {
        this._identityId = value;
    }

    public getMessage(): MultilingualString {
        return this._message;
    }

    public setMessage(message: MultilingualString) {
        this._message = message;
    }

    public getRelatedFields(): string[] {
        return this._relatedFields;
    }

    public setRelatedFields(relatedFields: string[]) {
        this._relatedFields = relatedFields;
    }

    public get validatorLabel(): string {
        return this._validatorLabel;
    }

    public set validatorLabel(value: string) {
        this._validatorLabel = value;
    }

    public get validationDisplay(): ValidationDisplay {
        return this._validationDisplay;
    }

    public set validationDisplay(value: ValidationDisplay) {
        this._validationDisplay = value;
    }
}

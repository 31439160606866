/**
 * @author Harut.Arakelyan on 3/15/2018
 */

export enum DeHeadMenuType {
  EXPORT_PDF = 1,
  EXPORT_RTF = 2,
  EXPORT_EXCEL = 3,
  HELP_MANUAL = 4,
  HELP_ONLINE = 5
}

/**
 * Created by Anushavan.Hovsepyan
 * on 10/6/2016.
 */
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {ValidationError} from './validation-error.model';

/**
 * Object which contains meta info from response.
 */
export class Meta {
    /**
     * Response data count.
     * @type {number}
     * @private
     */
    private _totalRowCount: number;

    /**
     * Array of validations of meta.
     * @type {Validation}
     * @private
     */
    private _validations: Validation[];

    /**
     * for backward compatibility (M&E)
     * todo: remove on next major release,
     *        along with setValidationErrors, getValidationErrors methods
     *        and setting this attribute in serialization-helper.ts
     */
    private _validationErrors: ValidationError[];

    public getTotalRowCount(): number {
        return this._totalRowCount;
    }

    public setTotalRowCount(value: number) {
        this._totalRowCount = value;
    }

    public getValidations(): Validation[] {
        return this._validations;
    }

    public setValidations(validationErrors: Validation[]) {
        this._validations = validationErrors;
    }

    public setValidationErrors(validationErrors: ValidationError[]) {
        this._validationErrors = validationErrors;
    }

    public getValidationErrors(): ValidationError[] {
        return this._validationErrors;
    }
}

/**
 * @author tatevik.marikyan
 * @since 12/03/2018
 */
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {ClassifierService} from '@synisys/idm-classifier-service-client-js/src/api/classifier.service';
import {
    deserializeSimpleFields,
    Entity,
    EntityBuilder,
    FilterBuilder,
    PagingOptions,
} from '@synisys/idm-de-core-frontend';
import {KbService} from '@synisys/idm-kb-service-client-js/src/api/kb.service';
import {
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js/src/model/meta-field';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';

import {ServiceResponse} from '../../api/model';
import {PortfolioService} from '../../api/service';
import {
    deserializeMeta,
    isCompoundField,
    isFieldValueNotEmpty,
} from '../../utilities';
import {MainEntity, Meta, ServiceResponseDefault} from '../model';
import {EntitySearchItem} from '../../../controls/entity-search/model';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import {CategoryPermissionType} from '@synisys/idm-um-permission-client-js';

@Injectable()
export class PortfolioSerializationService {
    /**
     * A factory for creating entities.
     * @type {EntityBuilder}
     * @private
     */
    protected _entityBuilder: EntityBuilder;

    constructor(
        private _kbService: KbService,
        private _languageService: LanguageService,
        private _classifierService: ClassifierService,
        private _portfolioService: PortfolioService
    ) {
        this._entityBuilder = new EntityBuilder();
    }

    public deserializeEntities(
        systemName: string,
        entitiesDataObservable: Observable<any>,
        compoundMainEntityfields?: MetaField[],
        categoryPermissionTypes?: CategoryPermissionType[]
    ): Observable<any> {
        const serviceResponse: ServiceResponseDefault<Entity[]> = new ServiceResponseDefault<
            Entity[]
        >();
        return entitiesDataObservable.pipe(
            switchMap((entitiesData: any) => {
                serviceResponse.setMeta(deserializeMeta(entitiesData.meta));
                if (entitiesData.data.length > 0) {
                    const entityObservables = entitiesData.data.map(
                        (entityData: any) => {
                            return this.deserializeEntity(
                                systemName,
                                entityData,
                                compoundMainEntityfields,
                                categoryPermissionTypes
                            );
                        }
                    );
                    return zip(...entityObservables);
                } else {
                    return of([]);
                }
            }),
            switchMap((data: any) => {
                serviceResponse.setData(data);
                return of(serviceResponse);
            })
        );
    }
    public deserializeEntitySearchItems(
        entitySearchItemsData$: Observable<any>,
        filterFields: string[],
        nestedEntityInstanceName: string
    ): Observable<ServiceResponse<EntitySearchItem[]>> {
        const serviceResponse: ServiceResponseDefault<any[]> = new ServiceResponseDefault<
            any[]
        >();

        return entitySearchItemsData$.pipe(
            map((entitiesData: any) => {
                let searchItems: any[];

                if (entitiesData.data.length > 0) {
                    searchItems = entitiesData.data.map((entityItem: any) => {
                        const filterFieldData: string[] = filterFields.map(
                            (filterField: string) => {
                                return entityItem[filterField];
                            }
                        );

                        return new EntitySearchItem(
                            entityItem[nestedEntityInstanceName],
                            filterFieldData.join(' | '),
                            entityItem
                        );
                    });
                }

                const meta: Meta = new Meta();
                meta.setTotalRowCount(entitiesData.meta.totalRowCount);
                serviceResponse.setMeta(meta);
                serviceResponse.setData(searchItems);

                return serviceResponse;
            })
        );
    }

    private deserializeEntity(
        systemName: string,
        entityData: any,
        compoundMainEntityfields?: MetaField[],
        categoryPermissionTypes?: CategoryPermissionType[]
    ): Observable<Entity> {
        const entityFieldsObservable: Observable<any> = this._kbService.getMetaFields(
            systemName
        );
        const languagesObservable: Observable<any> = this._languageService.getInputLanguages();
        return zip(entityFieldsObservable, languagesObservable).pipe(
            switchMap((data: any) => {
                const entityFields =
                    compoundMainEntityfields === undefined
                        ? data[0]
                        : compoundMainEntityfields;
                const languages = data[1];
                let entity: Entity = this._entityBuilder.createEntity<
                    MainEntity
                >(MainEntity, entityFields);
                entity = deserializeSimpleFields(
                    entityFields,
                    languages,
                    entity,
                    entityData
                );
                return this.deserializeCompoundFields(
                    systemName,
                    entityFields,
                    entity,
                    entityData,
                    categoryPermissionTypes
                );
            })
        );
    }

    private deserializeCompoundFields(
        systemaName: string,
        entityFields: MetaField[],
        entity: Entity,
        entityData: any,
        categoryPermissionTypes?: CategoryPermissionType[]
    ): Observable<Entity> {
        const entityCompoundFields: any[] = entityFields
            .filter(isCompoundField)
            .filter(field => {
                return isFieldValueNotEmpty(field, entity);
            });
        if (entityCompoundFields.length > 0) {
            const entityCompoundFieldObservables = entityCompoundFields.map(
                (field: MetaField) => {
                    return this.deserializeCompoundField(
                        systemaName,
                        field,
                        entityData[field.getSystemName()],
                        categoryPermissionTypes
                    );
                }
            );
            return zip(...entityCompoundFieldObservables).pipe(
                map(fieldValues => {
                    entityCompoundFields.forEach(
                        (field: MetaField, index: number) => {
                            entity.getProperty(field.getSystemName()).value =
                                fieldValues[index];
                        }
                    );
                    return entity;
                })
            );
        } else {
            return of(entity);
        }
    }

    private deserializeCompoundField(
        systemName: string,
        field: MetaField,
        fieldData: any,
        categoryPermissionTypes?: CategoryPermissionType[]
    ): Observable<any> {
        const fieldType: MetaFieldType = field.getType();
        switch (fieldType) {
            case MetaFieldType.CLASSIFIER:
            case MetaFieldType.WORKFLOW_STATE: {
                return this._classifierService.loadClassifier(
                    field.getCompoundCategorySystemName(),
                    fieldData
                );
            }
            case MetaFieldType.MAIN_ENTITY: {
                return this.deserializeCompoundMainEntity(
                    systemName,
                    field,
                    fieldData,
                    categoryPermissionTypes
                );
            }
            case MetaFieldType.MULTI_SELECT:
                const classifierObservables = fieldData.map((itemData: any) => {
                    return this._classifierService.loadClassifierDependencies(
                        field.getCompoundCategorySystemName(),
                        itemData
                    );
                });
                return zip(...classifierObservables).pipe(
                    switchMap(classifierEntities => {
                        return of(classifierEntities);
                    })
                );
            case MetaFieldType.SUB_ENTITY: {
                const subEntityObservables = fieldData.map((itemData: any) => {
                    return this.deserializeEntity(
                        field.getCompoundCategorySystemName(),
                        itemData,
                        undefined,
                        categoryPermissionTypes
                    );
                });
                return zip(...subEntityObservables).pipe(
                    switchMap(subEntities => {
                        return of(subEntities);
                    })
                );
            }
            default: {
                throw new Error(
                    `Illegal field type ${field.getType()} was provided.`
                );
            }
        }
    }

    private deserializeCompoundMainEntity(
        systemName: string,
        field: MetaField,
        fieldData: any,
        categoryPermissionTypes?: CategoryPermissionType[]
    ): Observable<any> {
        const metaFields$ = this._kbService.getMetaFields(
            field.getCompoundCategorySystemName()
        );
        const compoundMainEntityMetaFields$ = this._kbService.getMainEntityMetaFields(
            systemName,
            field.getSystemName()
        );

        return zip(metaFields$, compoundMainEntityMetaFields$).pipe(
            switchMap((data: MetaField[][]) => {
                let metaFieldSystemName: string;

                data[0].forEach((metaField: MetaField) => {
                    if (
                        metaField.getType() === MetaFieldType.INTEGER_INSTANCE
                    ) {
                        metaFieldSystemName = metaField.getSystemName();
                    }
                });

                if (
                    metaFieldSystemName !== undefined &&
                    metaFieldSystemName !== null
                ) {
                    const filterBuilder: FilterBuilder = new FilterBuilder();
                    filterBuilder.is(metaFieldSystemName, fieldData);

                    const mainEntityItems$ = this._portfolioService.loadPortfolioItems(
                        field.getCompoundCategorySystemName(),
                        new PagingOptions(0, 1),
                        null,
                        filterBuilder.build().toJson(),
                        categoryPermissionTypes,
                        data[1]
                    );
                    return mainEntityItems$.pipe(
                        map((value: ServiceResponse<Entity[]>) => {
                            return value.getData().length > 0
                                ? value.getData()[0]
                                : null;
                        })
                    );
                } else {
                    return of(null);
                }
            })
        );
    }
}

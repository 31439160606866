/**
 * Created by Hovhannes.Sahakyan
 * on 7/13/2018.
 */

export enum DisplayType {
    FIELDS,
    FUNCTIONAL,
}

export class ValidationDisplay {
    public static fromResponse(response: {
        displayType: string;
        displayData: object;
    }) {
        if (!response) {
            return null;
        }
        const validationDisplay = new ValidationDisplay();
        validationDisplay.displayType = DisplayType[response.displayType];
        if (response.displayData instanceof Array) {
            validationDisplay.displayData = new Map<string, any>([
                ['fields', response.displayData],
            ]);
        } else {
            const entries = Object.keys(response.displayData).map((k: string): [
                string,
                any
            ] => [k, response.displayData[k]]);
            validationDisplay.displayData = new Map<string, any>(entries);
        }
        return validationDisplay;
    }

    /**
     * Validation Display type
     * @type {DisplayType}
     * @private
     */
    private _displayType: DisplayType;

    /**
     * Validation Display Data
     * @type { Map<string, any>}
     * @private
     */
    private _displayData: Map<string, any>;

    public get displayType(): DisplayType {
        return this._displayType;
    }

    public set displayType(value: DisplayType) {
        this._displayType = value;
    }

    public get displayData(): Map<string, any> {
        return this._displayData;
    }

    public set displayData(value: Map<string, any>) {
        this._displayData = value;
    }
}

import {_throw as throwError} from 'rxjs/observable/throw';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';

import {PreconditionCheck} from '@synisys/idm-common-util-frontend';

/**
 * Common handler for response errors logging a message to the console and returning with a rejection.
 * @param {HttpErrorResponse} error - The error response that occurred.
 * @returns {ErrorObservable} An observable that emits an error notification using the given error argument.
 */
export function handleResponseError(error: HttpErrorResponse): ErrorObservable {
    PreconditionCheck.notNullOrUndefined(error);

    const message =
        error.error instanceof ErrorEvent
            ? `An error occurred: ${error.error.message}`
            : error;

    return throwError(message);
}

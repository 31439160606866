import {Store} from '@ngrx/store';
import {
    CurrencyState,
    getDefaultCurrency,
} from '@synisys/skynet-store-currencies-api';
import {catchError, first, flatMap, map, switchMap, tap} from 'rxjs/operators';
import {Injectable, Optional} from '@angular/core';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {
    ClassifierService,
    EntityAuditService,
} from '@synisys/idm-classifier-service-client-js';
import {
    PreconditionCheck,
    StringTemplate,
} from '@synisys/idm-common-util-frontend';
import {
    Language,
    MultilingualString,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    Entity,
    EntityBuilder,
    PagingOptions,
    SearchParam,
    serializeMultilingualString,
    SortingOptions,
    SortType,
    stringifyFieldValue,
} from '@synisys/idm-de-core-frontend';
import {
    KbService,
    MetaFieldId,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {
    LanguageService,
    MessageService,
} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {
    ValidationsCalculationsData,
    ValidationService,
} from '@synisys/idm-validation-calculation-service-client-js';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {noop} from 'rxjs/util/noop';

import {ServiceResponse} from '../../api/model';
import {DataService, DePermissionService, DeService} from '../../api/service';
import {DeSerializationService} from './de-serialization.service';
import {Meta, ResponseStatus, ServiceResponseDefault} from '..';
import {todayUTC} from '../../../shared/utilities';
import {deserializeMeta} from '../../utilities';
import {of} from 'rxjs/observable/of';
import {Observable} from 'rxjs/Observable';
import {from} from 'rxjs/observable/from';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {zip} from 'rxjs/observable/zip';
import {ActionDto} from '@synisys/idm-workflow-service-client-js';

type EntityData = {meta: object; data: object};

@Injectable()
export class HttpDeService implements DeService {
    public URL_ENTITY_LOAD_ONE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/${'systemName'}/${'entityId'}`;
    public URL_ENTITY_LOAD_MANY: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/${'systemName'}/flat`;
    public URL_ENTITY_ADD: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/${'systemName'}`;
    public URL_ENTITY_BULK_ACTION: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entities/${'categoryName'}`;
    public URL_ENTITY_UPDATE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/${'systemName'}/${'entityId'}`;
    public URL_ENTITY_DELETE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/${'systemName'}/${'entityId'}`;
    public URL_ENTITY_DELETE_INSTANCE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/${'systemName'}/instance/${'instanceId'}`;
    public URL_ENTITY_LOAD_HISTORICAL_ITEMS: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/${'systemName'}/flat/history/${'instanceId'}`;
    public URL_LOAD_ENTITY_BY_INSTANCE_ID: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/${'systemName'}/instance/${'entityInstanceId'}`;
    public URL_CHECK_WF_ACTIONS_EXPRESSIONS: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/workflow/${'systemName'}/${'entityId'}/action`;
    public URL_DO_WORKFLOW_ACTION: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/workflow/${'systemName'}/${'entityId'}/action/${'actionId'}`;
    public URL_DO_BULK_WORKFLOW_ACTION: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/bulkWorkflow/${'categoryName'}/action/${'actionId'}`;
    public URL_GET_CONFIG: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/config`;
    public URL_GET_CONFIGS_WITH_PREFIX: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/configs`;
    public URL_DE_DOCUMENT_TOKEN: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/token/${'systemName'}/${'entityId'}/${'documentSystemName'}`;
    public URL_DE_DOCUMENT_TOKEN_BY_DOCUMENT_ID: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/token/${'systemName'}/${'documentId'}`;
    public URL_GET_DEFAULT_ENTITY: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entity/defaults`;

    private DE_SERVICE_URI_KEY = 'de-service-url';
    private readonly DELETED_ENTITY_NAME_PREFIX_MESSAGE =
        'de.deleted.name.prefix';
    private _deSerializationService: DeSerializationService = null;
    private _entityBuilder: EntityBuilder = null;
    private defaultEntities$: ReplaySubject<string>;

    public constructor(
        private _dataService: DataService,
        private _kbService: KbService,
        private _languageService: LanguageService,
        private _classifierService: ClassifierService,
        private _currentLanguageProvider: CurrentLanguageProvider,
        private _applicationPropertiesService: ApplicationPropertiesService,
        private _validationCalculationService: ValidationService,
        private _dePermissionService: DePermissionService,
        @Optional() private store: Store<CurrencyState> | undefined,
        private _auditService: EntityAuditService,
        private _messageService: MessageService
    ) {
        this._deSerializationService = new DeSerializationService(
            _kbService,
            _languageService,
            _classifierService,
            _dePermissionService,
            this,
            _auditService
        );
        this._entityBuilder = new EntityBuilder();
    }

    /**
     * Create blank(empty) entity. This function is used when a new entity is created.
     * @param {string} categoryName - The unique identifier for entity.
     * @returns {Promise<Entity>}
     */
    public createBlankEntity(categoryName: string): Observable<Entity> {
        PreconditionCheck.notNullOrUndefined(categoryName);
        return this._deSerializationService.deserializeMainEntity(
            categoryName,
            this.getDefaultEntities().pipe(
                map((data: string) => {
                    const jsonData =
                        data && Object.keys(data).length !== 0
                            ? JSON.parse(data)
                            : undefined;
                    if (!jsonData || !jsonData[categoryName]) {
                        return {};
                    }
                    return jsonData[categoryName];
                }),
                switchMap(entity =>
                    this.setDefaultCurrency(categoryName, entity)
                )
            )
        );
    }

    /**
     * Creates blank(empty) entity. This function is used when a new entity is created.
     * @param {string} categoryName - The unique identifier for entity.
     * @returns {Observable<Entity>}
     */
    public createBlankSubEntity(categoryName: string): Observable<Entity> {
        return this._deSerializationService.deserializeSubEntity(
            categoryName,
            this.getDefaultEntities().pipe(
                map((data: string) => {
                    const jsonData =
                        data && Object.keys(data).length !== 0
                            ? JSON.parse(data)
                            : undefined;
                    if (!jsonData || !jsonData[categoryName]) {
                        return [];
                    }
                    return jsonData[categoryName];
                })
            )
        );
    }

    /**
     * Loads item with the given ID of a main entity specified by a systemName.
     * @param {string} systemName - The unique identifier for entity.
     * @param {number} entityId - An identity of the instance to be fetched.
     * @param {boolean} [withTransientFields]
     * @returns {Promise<Entity>} the promise resolving with the entity loaded
     */
    public loadEntity(
        systemName: string,
        entityId: number,
        withTransientFields?: boolean
    ): Observable<Entity> {
        PreconditionCheck.notNullOrUndefined(systemName);
        PreconditionCheck.notNullOrUndefined(entityId);
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_ENTITY_LOAD_ONE.replaceTemplate({
                    entityId: entityId.toString(),
                    serviceUri,
                    systemName,
                });
                const entityDataObservable = this._currentLanguageProvider
                    .getCurrentLanguage()
                    .pipe(
                        first(),
                        switchMap(language => {
                            const queryParams: SearchParam<any>[] = [];
                            queryParams.push(
                                new SearchParam('languageId', language.getId())
                            );
                            queryParams.push(
                                new SearchParam(
                                    'withTransientFields',
                                    withTransientFields !== undefined &&
                                        withTransientFields
                                )
                            );
                            return this._dataService.load(url, queryParams);
                        })
                    );
                return this._deSerializationService.deserializeMainEntity(
                    systemName,
                    entityDataObservable
                );
            })
        );
    }

    /**
     * Loads items with the given IDs of a main entity specified by a systemName.
     * @param {string} systemName - The unique identifier for entity.
     * @param {PagingOptions} paging - The paging to serve entries in accordance.
     * @param {string} filterCriteria - json representation of Filter Criteria
     * @returns {Promise<Array<Promise<Entity>>>} the promise resolving with the collection of entities loaded
     */
    public loadEntities(
        systemName: string,
        paging?: PagingOptions,
        filterCriteria?: string
    ): Observable<any> {
        PreconditionCheck.notNullOrUndefined(systemName);
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_ENTITY_LOAD_MANY.replaceTemplate({
                    serviceUri,
                    systemName,
                });
                const entitiesDataObservable = this._currentLanguageProvider
                    .getCurrentLanguage()
                    .pipe(
                        first(),
                        switchMap(language => {
                            const queryParams: SearchParam<any>[] = [];
                            queryParams.push(
                                new SearchParam('languageId', language.getId())
                            );
                            if (!paging) {
                                paging = new PagingOptions(0, 100);
                            }
                            queryParams.push(new SearchParam('paging', paging));
                            queryParams.push(
                                new SearchParam(
                                    'filterCriteria',
                                    filterCriteria
                                )
                            );
                            return this._dataService.load(url, queryParams);
                        })
                    );
                return this._deSerializationService.deserializeEntities(
                    systemName,
                    entitiesDataObservable
                );
            })
        );
    }

    /**
     * Loads items with the given IDs of a main entity specified by a categoryName.
     * @param {string} categoryName - Category Name.
     * @param {number[]} instanceIds - Instance ids
     * @param {boolean} withTransientFields - get with transient fields, default false
     * @returns {Promise<Array<Promise<Entity>>>} the promise resolving with the collection of entities loaded
     */
    public getEntitiesByIds(
        categoryName: string,
        instanceIds: number[],
        withTransientFields?: boolean
    ): Observable<Entity> {
        const serviceUri$ = from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        );
        const currentLanguageId$ = this._currentLanguageProvider
            .getCurrentLanguage()
            .pipe(
                first(),
                map(language => language.getId())
            );
        return combineLatest(serviceUri$, currentLanguageId$).pipe(
            first(),
            switchMap((data: [string, number]) => {
                const serviceUri = data[0];
                const currentLanguageId = data[1];
                const url: string = this.URL_ENTITY_BULK_ACTION.replaceTemplate(
                    {serviceUri, categoryName}
                );
                const queryParams: SearchParam<any>[] = [];
                queryParams.push(
                    new SearchParam('languageId', currentLanguageId)
                );
                if (withTransientFields !== undefined) {
                    queryParams.push(
                        new SearchParam(
                            'withTransientFields',
                            withTransientFields
                        )
                    );
                }
                if (instanceIds) {
                    queryParams.push(
                        new SearchParam('ids', instanceIds.join())
                    );
                }
                const entitiesDataObservable = this._dataService.load(
                    url,
                    queryParams
                );
                return this._deSerializationService.deserializeEntities(
                    categoryName,
                    entitiesDataObservable
                );
            })
        );
    }

    /**
     * Serializes and saves the newly created entity item.
     * @param {string} systemName - The unique identifier for entity.
     * @param {Entity} entity - The serialized entity item to be saved.
     * @returns {Promise<ServiceResponse<Entity>>}
     */
    public add(systemName: string, entity: Entity): Observable<any> {
        let queryParams: SearchParam<any>[];
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_ENTITY_ADD.replaceTemplate({
                    serviceUri,
                    systemName,
                });
                return this._currentLanguageProvider.getCurrentLanguage().pipe(
                    first(),
                    switchMap(language => {
                        queryParams = [];
                        queryParams.push(
                            new SearchParam('languageId', language.getId())
                        );
                        return this._deSerializationService.serializeEntity(
                            systemName,
                            entity
                        );
                    }),
                    switchMap((data: any) => {
                        return this._dataService.add(url, data, queryParams);
                    }),
                    switchMap(
                        (data: any): Observable<any> => {
                            return this._deSerializationService.deserializeMainEntityWithMeta(
                                systemName,
                                data
                            );
                        }
                    )
                );
            })
        );
    }

    /**
     * Serializes and saves the newly created entities
     */
    public bulkAdd(
        categoryName: string,
        entities: Entity[],
        raiseEvent?: boolean,
        enableCrossMasterValidations?: boolean,
        withOneTransaction?: boolean,
        deserializableFields?: MetaFieldId[]
    ): Observable<ServiceResponseDefault<object>[]> {
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap(
                (
                    serviceUri: string
                ): Observable<ServiceResponseDefault<object>[]> => {
                    const url: string = this.URL_ENTITY_BULK_ACTION.replaceTemplate(
                        {
                            serviceUri,
                            categoryName,
                        }
                    );
                    return this.bulkSaveUpdateHelp(
                        categoryName,
                        entities,
                        true,
                        raiseEvent,
                        enableCrossMasterValidations,
                        withOneTransaction
                    ).pipe(
                        switchMap(result => {
                            return this._dataService
                                .add(
                                    url,
                                    result['payload'],
                                    result['queryParams']
                                )
                                .pipe(
                                    map((entitiesData: EntityData[]) =>
                                        !deserializableFields
                                            ? entitiesData
                                            : entitiesData.map(entityData => {
                                                  const result = {};
                                                  Object.keys(entityData.data)
                                                      .filter(
                                                          fieldSystemName =>
                                                              deserializableFields // filter only given fields from entity
                                                                  .map(
                                                                      deserializableField =>
                                                                          deserializableField.getSystemName()
                                                                  )
                                                                  .indexOf(
                                                                      fieldSystemName
                                                                  ) !== -1
                                                      )
                                                      .forEach(
                                                          filteredFieldSystemName =>
                                                              (result[
                                                                  filteredFieldSystemName
                                                              ] =
                                                                  entityData.data[
                                                                      filteredFieldSystemName
                                                                  ])
                                                      );
                                                  entityData.data = result;
                                                  return entityData;
                                              })
                                    ),
                                    switchMap((entitiesData: EntityData[]) => {
                                        const response: ServiceResponseDefault<
                                            object
                                        >[] = Array.from(entitiesData).map(
                                            entityData => {
                                                const serviceResponse = new ServiceResponseDefault<
                                                    object
                                                >();
                                                serviceResponse.setMeta(
                                                    deserializeMeta(
                                                        entityData.meta
                                                    )
                                                );
                                                serviceResponse.setData(
                                                    entityData.data
                                                );
                                                return serviceResponse;
                                            }
                                        );
                                        return of(response);
                                    })
                                );
                        }),
                        catchError(err => {
                            console.log(err);
                            return of(null);
                        })
                    );
                }
            )
        );
    }

    /**
     * Serializes and saves the modified entity item.
     * @param {string} systemName - The unique identifier for entity.
     * @param {Entity} entity - The entity item to be saved.
     * @returns {Promise<ServiceResponse<Entity>>}
     */
    public update(systemName: string, entity: Entity): Observable<any> {
        let queryParams: SearchParam<any>[];
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_ENTITY_UPDATE.replaceTemplate({
                    entityId: entity.getId().toString(),
                    serviceUri,
                    systemName,
                });
                return this._currentLanguageProvider.getCurrentLanguage().pipe(
                    first(),
                    switchMap(language => {
                        queryParams = [];
                        queryParams.push(
                            new SearchParam('languageId', language.getId())
                        );
                        return this._deSerializationService.serializeEntity(
                            systemName,
                            entity
                        );
                    }),
                    switchMap((data: any) => {
                        return this._dataService.update(url, data, queryParams);
                    }),
                    switchMap(
                        (data: any): Observable<any> => {
                            return this._deSerializationService.deserializeMainEntityWithMeta(
                                systemName,
                                data
                            );
                        }
                    )
                );
            })
        );
    }

    /**
     * Serializes and saves already created items
     */
    public bulkUpdate(
        categoryName: string,
        entities: Entity[],
        raiseEvent?: boolean,
        enableCrossMasterValidations?: boolean,
        withOneTransaction?: boolean
    ): Observable<Map<number, Meta>> {
        const entities$ = combineLatest(
            from(
                this._applicationPropertiesService.getProperty(
                    this.DE_SERVICE_URI_KEY
                )
            ),
            this.bulkSaveUpdateHelp(
                categoryName,
                entities,
                false,
                raiseEvent,
                enableCrossMasterValidations,
                withOneTransaction
            )
        ).pipe(
            first(),
            switchMap((data: [string, object]) => {
                const serviceUri = data[0];
                const url = this.URL_ENTITY_BULK_ACTION.replaceTemplate({
                    serviceUri,
                    categoryName,
                });
                return this._dataService.update(
                    url,
                    data[1]['payload'],
                    data[1]['queryParams']
                );
            })
        );
        const instanceMetaField$ = this._kbService
            .getMetaFields(categoryName)
            .pipe(
                map(
                    metaFields =>
                        metaFields.filter(
                            metaField =>
                                metaField.getType() ===
                                MetaFieldType.INTEGER_INSTANCE
                        )[0]
                )
            );
        return combineLatest(entities$, instanceMetaField$).pipe(
            first(),
            map(data => {
                const response = data[0];
                const instanceMetaField = data[1];

                return new Map(
                    Array.from(response).map((entityData): [number, Meta] => [
                        entityData['data'][instanceMetaField.getSystemName()],
                        deserializeMeta(entityData['meta']),
                    ])
                );
            })
        );
    }

    /**
     * Deletes the entity item.
     * @deprecated Since version 1.3.0. Use {@link #HttpDeService.deleteById} instead.
     * @param {string} systemName - The unique identifier for entity.
     * @param {Entity} entity - The entity item to be deleted.
     * @returns {Observable<any>}
     */
    public delete(systemName: string, entity: Entity): Observable<any> {
        return this.deleteById(systemName, entity.getId());
    }

    /**
     * Deletes the entity item.
     * @param {string} systemName - The unique identifier for entity.
     * @param {number} entityId - The ID of entity item to be deleted.
     * @returns {Observable<any>}
     */
    public deleteById(systemName: string, entityId: number): Observable<any> {
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_ENTITY_DELETE.replaceTemplate({
                    entityId: entityId.toString(),
                    serviceUri,
                    systemName,
                });
                return this._currentLanguageProvider.getCurrentLanguage().pipe(
                    first(),
                    switchMap((language: Language) => {
                        const queryParams: SearchParam<any>[] = [];
                        queryParams.push(
                            new SearchParam('languageId', language.getId())
                        );
                        return this._dataService.delete(url, queryParams);
                    })
                );
            })
        );
    }

    /**
     * Deletes the entity item.
     * @param {string} systemName - The unique identifier for entity.
     * @param {number} instanceId - The Instance ID of entity item to be deleted.
     * @returns {Observable<any>}
     */
    public deleteByInstanceId(
        systemName: string,
        instanceId: number
    ): Observable<any> {
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_ENTITY_DELETE_INSTANCE.replaceTemplate(
                    {
                        instanceId: instanceId.toString(),
                        serviceUri,
                        systemName,
                    }
                );
                return this._currentLanguageProvider.getCurrentLanguage().pipe(
                    first(),
                    switchMap((language: Language) => {
                        const queryParams: SearchParam<any>[] = [];
                        queryParams.push(
                            new SearchParam('languageId', language.getId())
                        );
                        return this._dataService.delete(url, queryParams);
                    })
                );
            })
        );
    }

    /**
     * Loads history versions and their count.
     *
     * @param {string} systemName - The unique identifier for entity.
     * @param {number} instanceId - The instance id which versions must be load.
     * @param {PagingOptions} pagingOptions - The pagingOptions to serve entity history in accordance.
     * @param {SortingOptions} [sortingOptions] - The sortingOptions to serve entity history in accordance.
     * @param {string[]} [loadableFields] - Entity fields which must be load.
     *                                      If array is empty, null undefined - load all fields
     *
     * @returns {Promise<ServiceResponse<Entity[]>>} the promise resolving with the history loaded
     */
    public loadHistoricalEntityItems(
        systemName: string,
        instanceId: number,
        pagingOptions: PagingOptions,
        sortingOptions?: SortingOptions,
        loadableFields?: string[]
    ): Observable<any> {
        PreconditionCheck.notNullOrUndefined(systemName);

        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_ENTITY_LOAD_HISTORICAL_ITEMS.replaceTemplate(
                    {
                        instanceId: instanceId.toString(),
                        serviceUri,
                        systemName,
                    }
                );
                const entitiesDataObservable = this._currentLanguageProvider
                    .getCurrentLanguage()
                    .pipe(
                        first(),
                        switchMap((language: Language) => {
                            const queryParams: SearchParam<any>[] = [];
                            queryParams.push(
                                new SearchParam('languageId', language.getId())
                            );
                            if (
                                pagingOptions !== undefined &&
                                pagingOptions !== null
                            ) {
                                queryParams.push(
                                    new SearchParam('paging', {
                                        limit: pagingOptions.limit,
                                        offset: pagingOptions.offset,
                                    })
                                );
                            }
                            if (
                                loadableFields !== undefined &&
                                loadableFields !== null &&
                                loadableFields.length > 0
                            ) {
                                queryParams.push(
                                    new SearchParam(
                                        'metaFields',
                                        loadableFields.toString()
                                    )
                                );
                            }
                            if (
                                sortingOptions !== undefined &&
                                sortingOptions !== null
                            ) {
                                queryParams.push(
                                    new SearchParam(
                                        'sortField',
                                        sortingOptions.sortField
                                    )
                                );
                                queryParams.push(
                                    new SearchParam(
                                        'sortOrder',
                                        sortingOptions.sortType === SortType.ASC
                                            ? 'ASC'
                                            : 'DESC'
                                    )
                                );
                            }
                            return this._dataService.load(url, queryParams);
                        })
                    );

                return this._deSerializationService.deserializeEntities(
                    systemName,
                    entitiesDataObservable
                );
            })
        );
    }

    /**
     * Loads item with the given instance ID of a main entity specified by a systemName.
     * @param {string} systemName
     * @param {number} entityInstanceId
     * @param {boolean} [withTransientFields]
     * @returns {Observable<any>}
     */
    public loadEntityByInstanceId(
        systemName: string,
        entityInstanceId: number,
        withTransientFields?: boolean,
        fieldSystemNames?: string[]
    ): Observable<any> {
        PreconditionCheck.notNullOrUndefined(systemName);
        PreconditionCheck.notNullOrUndefined(entityInstanceId);
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_LOAD_ENTITY_BY_INSTANCE_ID.replaceTemplate(
                    {
                        entityInstanceId: entityInstanceId.toString(),
                        serviceUri,
                        systemName,
                    }
                );
                const entityDataObservable = this._currentLanguageProvider
                    .getCurrentLanguage()
                    .pipe(
                        first(),
                        switchMap(language => {
                            const queryParams: SearchParam<any>[] = [];
                            queryParams.push(
                                new SearchParam('languageId', language.getId())
                            );
                            queryParams.push(
                                new SearchParam(
                                    'withTransientFields',
                                    withTransientFields !== undefined &&
                                        withTransientFields
                                )
                            );
                            if (fieldSystemNames) {
                                queryParams.push(
                                    new SearchParam(
                                        'metaFields',
                                        fieldSystemNames
                                    )
                                );
                            }
                            return this._dataService.load(url, queryParams);
                        })
                    );
                return this._deSerializationService.deserializeMainEntity(
                    systemName,
                    entityDataObservable,
                    fieldSystemNames
                );
            })
        );
    }

    public getPermittedActions(
        categoryName: string,
        entityId: number
    ): Observable<ActionDto[]> {
        let queryParams: SearchParam<any>[];
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_CHECK_WF_ACTIONS_EXPRESSIONS.replaceTemplate(
                    {
                        serviceUri: serviceUri,
                        systemName: categoryName,
                        entityId: entityId.toString(),
                    }
                );
                return this._currentLanguageProvider.getCurrentLanguage().pipe(
                    first(),
                    switchMap(language => {
                        queryParams = [];
                        queryParams.push(
                            new SearchParam('languageId', language.getId())
                        );
                        return this._dataService
                            .load(url, queryParams)
                            .pipe(
                                map((actionDtos: any[]) =>
                                    actionDtos.map(
                                        actionDto =>
                                            new ActionDto(
                                                actionDto.id,
                                                this.convertObjectToMap(
                                                    actionDto.name
                                                ),
                                                this.convertObjectToMap(
                                                    actionDto.description || {}
                                                ),
                                                actionDto.initialStateId,
                                                actionDto.targetStateId,
                                                actionDto.formId
                                            )
                                    )
                                )
                            );
                    })
                );
            })
        );
    }

    /**
     * Does workflow action with the given entity and action ID.
     * @param {string} systemName
     * @param {Entity} entity
     * @param {number} actionId
     * @returns {Observable<any>}
     */
    public doWorkflowAction(
        systemName: string,
        entity: Entity,
        actionId: number
    ): Observable<any> {
        let queryParams: SearchParam<any>[];
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_DO_WORKFLOW_ACTION.replaceTemplate(
                    {
                        actionId: actionId.toString(),
                        entityId: entity.getId().toString(),
                        serviceUri,
                        systemName,
                    }
                );
                return this._currentLanguageProvider.getCurrentLanguage().pipe(
                    first(),
                    switchMap(language => {
                        queryParams = [];
                        queryParams.push(
                            new SearchParam('languageId', language.getId())
                        );
                        return this._deSerializationService.serializeEntity(
                            systemName,
                            entity
                        );
                    }),
                    switchMap((data: any) => {
                        return this._dataService.update(url, data, queryParams);
                    }),
                    switchMap(
                        (data: any): Observable<any> => {
                            return this._deSerializationService.deserializeMainEntityWithMeta(
                                systemName,
                                data
                            );
                        }
                    )
                );
            })
        );
    }

    public doBulkWorkflowAction(
        categoryName: string,
        entityIds: number[],
        actionId: number,
        comment: MultilingualString
    ): Observable<Map<number, ResponseStatus>> {
        const currentLanguage$ = this._currentLanguageProvider
            .getCurrentLanguage()
            .pipe(first());
        const languages = this._languageService.getInputLanguages();
        const serviceUri$ = from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        );

        return zip(currentLanguage$, serviceUri$, languages).pipe(
            switchMap((data: [Language, string, Language[]]) => {
                const serviceUri = data[1];
                const url: string = this.URL_DO_BULK_WORKFLOW_ACTION.replaceTemplate(
                    {
                        actionId: actionId.toString(),
                        serviceUri,
                        categoryName,
                    }
                );
                const body = {};
                body['instanceIds'] = entityIds;
                body['lastActionComment'] = serializeMultilingualString(
                    comment,
                    data[2]
                );
                return this._dataService.update(url, body, [
                    new SearchParam('languageId', data[0].getId()),
                ]);
            }),
            map((response: object) => {
                const result = new Map<number, ResponseStatus>();
                for (const key in response) {
                    if (response.hasOwnProperty(key)) {
                        result.set(+key, response[key]);
                    }
                }
                return result;
            })
        );
    }

    /**
     * Validates given Entity for existing validations
     * @param {string} systemName
     * @param {Entity} entity
     * @returns {Observable<any>}
     */
    public validateEntity(systemName: string, entity: Entity): Observable<any> {
        return this._deSerializationService
            .serializeEntity(systemName, entity)
            .pipe(
                switchMap((entityData: any) => {
                    return this._validationCalculationService.validateEntity(
                        systemName,
                        entityData
                    );
                })
            );
    }

    /**
     * Validate and Calculate given Entity
     * Returned result (ValidationsCalculationsData) contains ValidationErrors, Calculation Warnings
     * and deserialized MainEntity
     * @param {string} systemName
     * @param {Entity} entity
     * @returns {Observable<ValidationsCalculationsData>}
     */
    public validateAndCalculateEntity(
        systemName: string,
        entity: Entity
    ): Observable<ValidationsCalculationsData> {
        let validationCalculationData: ValidationsCalculationsData;
        return this._deSerializationService
            .serializeEntity(systemName, entity)
            .pipe(
                switchMap((entityData: any) => {
                    return this._validationCalculationService.calculateAndValidateEntity(
                        systemName,
                        entityData
                    );
                }),
                switchMap((data: ValidationsCalculationsData) => {
                    validationCalculationData = data;
                    return this._deSerializationService.deserializeMainEntity(
                        systemName,
                        of(validationCalculationData.getData())
                    );
                }),
                map((deserializedEntity: any) => {
                    return new ValidationsCalculationsData(
                        validationCalculationData.getValidations(),
                        validationCalculationData.getCalculations(),
                        deserializedEntity
                    );
                })
            );
    }

    /**
     * Validate and Calculate field of given Entity
     * Returned result (ValidationsCalculationsData) contains ValidationErrors, Calculation Warnings
     * @param {string} systemName
     * @param {string} fieldSystemName
     * @param {Entity} entity
     * @returns {Observable<any>}
     */
    public validateAndCalculateField(
        systemName: string,
        fieldSystemName: string,
        entity: Entity
    ): Observable<ValidationsCalculationsData> {
        let validationCalculationData: ValidationsCalculationsData;
        return this._deSerializationService
            .serializeEntity(systemName, entity)
            .pipe(
                switchMap((entityData: any) => {
                    return this._validationCalculationService.calculateAndValidateField(
                        systemName,
                        fieldSystemName,
                        entityData
                    );
                }),
                switchMap((data: ValidationsCalculationsData) => {
                    validationCalculationData = data;
                    return this._deSerializationService.deserializeMainEntity(
                        systemName,
                        of(validationCalculationData.getData())
                    );
                }),
                map((deserializedEntity: any) => {
                    return new ValidationsCalculationsData(
                        validationCalculationData.getValidations(),
                        validationCalculationData.getCalculations(),
                        deserializedEntity
                    );
                })
            );
    }

    /**
     * Validates only the field of the given category.
     * @param {string} systemName
     * @param {string} fieldSystemName
     * @param {Entity} entity
     * @returns {Observable<any>}
     */
    public validateField(
        systemName: string,
        fieldSystemName: string,
        entity: Entity
    ): Observable<any> {
        return this._deSerializationService
            .serializeEntity(systemName, entity)
            .pipe(
                switchMap((entityData: any) => {
                    return this._validationCalculationService.validateField(
                        systemName,
                        fieldSystemName,
                        entityData
                    );
                })
            );
    }

    public getConfig(
        systemName: string,
        path: string,
        name: string
    ): Observable<any> {
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const queryParams: SearchParam<any>[] = [];
                queryParams.push(new SearchParam('path', path));
                queryParams.push(new SearchParam('name', name));
                const url: string = this.URL_GET_CONFIG.replaceTemplate({
                    serviceUri,
                });
                return this._dataService.load(url, queryParams);
            })
        );
    }

    public getConfigs(systemName: string, prefix: string): Observable<any> {
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const queryParams: SearchParam<any>[] = [];
                queryParams.push(new SearchParam('prefix', prefix));
                const url: string = this.URL_GET_CONFIGS_WITH_PREFIX.replaceTemplate(
                    {serviceUri}
                );
                return this._dataService.load(url, queryParams);
            })
        );
    }

    /**
     * Loads document token from de service.
     * @param {string} systemName - systemName of document's parent entity(aka MainEntity).
     * @param {number} entityId - id of document's parent entity
     * @param {string} documentSystemName - systemName of document field
     * @param {string} permissionType - permissionType to get document token. Supported types are DOWNLOAD or UPLOAD
     * @returns {Observable<string>} - Observable of loaded token.
     */
    public getDocumentToken(
        systemName: string,
        entityId: number,
        documentSystemName: string,
        permissionType: string = 'DOWNLOAD'
    ): Observable<string> {
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const queryParams: SearchParam<string>[] = [];
                queryParams.push(
                    new SearchParam('permissionType', permissionType)
                );
                const loadUrl = this.URL_DE_DOCUMENT_TOKEN.replaceTemplate({
                    documentSystemName,
                    entityId: entityId.toString(),
                    serviceUri,
                    systemName,
                });
                return this._dataService.load(loadUrl, queryParams);
            })
        );
    }

    public getDocumentTokenByDocumentId(
        systemName: string,
        documentId: number,
        permissionType: string = 'DOWNLOAD'
    ): Observable<string> {
        return from(
            this._applicationPropertiesService.getProperty(
                this.DE_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const queryParams: SearchParam<string>[] = [];
                queryParams.push(
                    new SearchParam('permissionType', permissionType)
                );
                const loadUrl = this.URL_DE_DOCUMENT_TOKEN_BY_DOCUMENT_ID.replaceTemplate(
                    {
                        serviceUri,
                        systemName,
                        documentId: documentId.toString(),
                    }
                );
                return this._dataService.load(loadUrl, queryParams);
            })
        );
    }

    public getEntityName(
        categorySystemName: string,
        entity: Entity,
        separator: string = ' '
    ): Observable<string> {
        if (entity === null) {
            return of(null);
        }
        const currentLanguage$ = this._currentLanguageProvider
            .getCurrentLanguage()
            .pipe(first());
        const nameMetaFieldIds$ = this._kbService.getNameMetaFieldIds(
            categorySystemName
        );
        const deletedMessage$ = this._messageService.getMessage(
            this.DELETED_ENTITY_NAME_PREFIX_MESSAGE
        );

        return combineLatest(
            currentLanguage$,
            nameMetaFieldIds$,
            deletedMessage$
        ).pipe(
            flatMap((data: [Language, MetaFieldId[], string]) => {
                let language: Language = data[0];
                let nameMetaFieldIds: Array<MetaFieldId> = data[1];
                let prefix = data[2];
                let names$: Observable<string>[] = [];
                nameMetaFieldIds.forEach(nameMetaFieldId => {
                    let nameMetaField$ = this._kbService.getMetaFieldByMetaFieldId(
                        nameMetaFieldId
                    );
                    names$.push(
                        nameMetaField$.map(nameMetaField =>
                            stringifyFieldValue(
                                nameMetaField,
                                entity.getProperty(
                                    nameMetaField.getSystemName()
                                ).value,
                                language
                            )
                        )
                    );
                });
                if (names$.length > 0) {
                    return zip(...names$).pipe(
                        map((names: string[]) =>
                            entity.isDeleted()
                                ? prefix + names.filter(Boolean).join(separator)
                                : names.filter(Boolean).join(separator)
                        )
                    );
                } else {
                    return of('');
                }
            })
        );
    }

    protected convertObjectToMap(obj: Object): Map<number, string> {
        const strMap = new Map<number, string>();
        for (const prop of Object.keys(obj)) {
            strMap.set(Number(prop), obj[prop]);
        }

        return strMap;
    }

    private getDefaultEntities(): Observable<string> {
        if (!this.defaultEntities$) {
            this.defaultEntities$ = new ReplaySubject<string>(1);
            from(
                this._applicationPropertiesService.getProperty(
                    this.DE_SERVICE_URI_KEY
                )
            )
                .pipe(
                    switchMap((serviceUri: string) => {
                        const url: string = this.URL_GET_DEFAULT_ENTITY.replaceTemplate(
                            {serviceUri}
                        );
                        return this._dataService.load(url);
                    }),
                    tap(data => this.defaultEntities$.next(data))
                )
                .subscribe(noop, console.error);
        }
        return this.defaultEntities$;
    }

    private bulkSaveUpdateHelp(
        categoryName: string,
        entities: Entity[],
        create: boolean,
        raiseEvent?: boolean,
        enableCrossMasterValidations?: boolean,
        withOneTransaction?: boolean
    ): Observable<object> {
        const queryParams: SearchParam<number | boolean>[] = [];
        if (raiseEvent !== undefined) {
            queryParams.push(new SearchParam('raiseEvent', raiseEvent));
        }
        if (enableCrossMasterValidations !== undefined) {
            queryParams.push(
                new SearchParam(
                    'enableCrossMasterValidations',
                    enableCrossMasterValidations
                )
            );
        }
        if (withOneTransaction !== undefined) {
            queryParams.push(
                new SearchParam('withOneTransaction', withOneTransaction)
            );
        }

        const serializedEntities$ = this._deSerializationService.bulkSerializeEntities(
            categoryName,
            entities,
            create
        );
        const currentLanguage$ = this._currentLanguageProvider
            .getCurrentLanguage()
            .pipe(first());

        return combineLatest(serializedEntities$, currentLanguage$).pipe(
            first(),
            map((data: [object[], Language]) => {
                queryParams.push(
                    new SearchParam('languageId', data[1].getId())
                );
                return {payload: data[0], queryParams: queryParams};
            })
        );
    }

    private setDefaultCurrency(
        categoryName: string,
        entity: object
    ): Observable<object> {
        if (this.store === undefined) {
            return of(entity);
        }

        return this._kbService.getMetaFields(categoryName).pipe(
            first(),
            switchMap(fields => {
                const newValues$: Observable<object>[] = fields
                    .map(field => {
                        if (
                            entity[field.getSystemName()] !== null &&
                            entity[field.getSystemName()] !== undefined
                        ) {
                            return undefined;
                        }
                        switch (field.getType()) {
                            case MetaFieldType.WORKFLOW_STATE:
                            case MetaFieldType.MAIN_ENTITY:
                            case MetaFieldType.CLASSIFIER:
                                // TODO
                                return undefined;
                            case MetaFieldType.SUB_ENTITY:
                            case MetaFieldType.MULTI_SELECT:
                                // TODO
                                return undefined;
                            case MetaFieldType.MONEY:
                                return getDefaultCurrency(this.store).pipe(
                                    first(),
                                    map(value => ({
                                        [field.getSystemName()]: {
                                            amount: undefined,
                                            currencyId: value.id,
                                        },
                                    }))
                                );
                            case MetaFieldType.ACCOUNTING:
                                return getDefaultCurrency(this.store).pipe(
                                    first(),
                                    map(value => ({
                                        [field.getSystemName()]: {
                                            originalMoney: {
                                                amount: undefined,
                                                currencyId: value.id,
                                            },
                                            rateToDefault: 1,
                                            timestamp: todayUTC(),
                                        },
                                    }))
                                );
                            default:
                                return undefined;
                        }
                    })
                    .filter(v => v !== undefined);
                if (newValues$.length === 0) {
                    return of(entity);
                }
                return combineLatest(newValues$).pipe(
                    map(newValues => Object.assign({}, ...newValues)),
                    map(newValues => Object.assign(entity, newValues))
                );
            })
        );
    }
}

import {catchError, first, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {
    Classifier,
    ClassifierService,
    ClassifiersResponse,
    EntityAuditService,
} from '@synisys/idm-classifier-service-client-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    deserializeSimpleFields,
    Entity,
    EntityBuilder,
    serializeSimpleField,
} from '@synisys/idm-de-core-frontend';
import {
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';

import {DePermissionService, DeService} from '../../api/service';
import {
    DePermission,
    MainEntity,
    ServiceResponseDefault,
    SubEntity,
} from '../model';

import {
    deserializeMeta,
    isCompoundField,
    isFieldValueNotEmpty,
} from '../../utilities';
import {Observable} from 'rxjs/Observable';
import {zip} from 'rxjs/observable/zip';
import {of} from 'rxjs/observable/of';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {
    Access,
    CategoryPermissionValueDto,
    PermissionType,
} from '@synisys/idm-authorization-client-js';
import {_throw} from 'rxjs/observable/throw';

@Injectable()
export class DeSerializationService {
    /**
     * A factory for creating entities.
     * @type {EntityBuilder}
     * @private
     */
    protected _entityBuilder: EntityBuilder;
    private readonly MAX_URL_LENGTH = 1900;

    constructor(
        private _kbService: KbService,
        private _languageService: LanguageService,
        private _classifierService: ClassifierService,
        private _dePermissionService: DePermissionService,
        private _deService: DeService,
        private _auditService: EntityAuditService
    ) {
        this._entityBuilder = new EntityBuilder();
    }

    /**
     * Deserializes the collection of entity items.
     * @param {string} systemName - The unique identifier for entity.
     * @param {Array.<any>} entitiesDataObservable - JSON object containing the data of the entity items.
     * @returns {Promise<Array<Promise<Entity>>>} the array of entity objects wrapped in a promise
     */
    public deserializeEntities(
        systemName: string,
        entitiesDataObservable: Observable<any>
    ): Observable<any> {
        const serviceResponse: ServiceResponseDefault<Entity[]> = new ServiceResponseDefault<
            Entity[]
        >();
        const entities: Entity[] = [];
        serviceResponse.setData(entities);
        const entityFieldsObservable = this._kbService.getMetaFields(
            systemName
        );
        const languagesObservable = this._languageService.getInputLanguages();
        return zip(
            entityFieldsObservable,
            entitiesDataObservable,
            languagesObservable
        ).pipe(
            switchMap((data: any) => {
                const entityFields = data[0];
                const entitiesData = data[1];
                const languages = data[2];
                serviceResponse.setMeta(deserializeMeta(entitiesData.meta));
                entitiesData.data.forEach((entityData: any) => {
                    const entity: Entity = this._entityBuilder.createEntity<
                        MainEntity
                    >(MainEntity, entityFields);
                    entities.push(entity);
                    deserializeSimpleFields(
                        entityFields,
                        languages,
                        entity,
                        entityData
                    );
                });
                return this.deserializeEntityCompoundFields(
                    entityFields,
                    entities,
                    systemName
                );
            }),
            switchMap((data: any) => {
                return of(serviceResponse);
            })
        );
    }

    /**
     * Deserializes a single entity item.
     * @param {string} systemName - The unique identifier for entity.
     * @param {any} entityDataObservable - JSON object containing data for a single entity item.
     * @param {Entity} entity
     * @param {Array<any>} entityFields
     * @returns {Promise<Entity>} entity object wrapped in a promise
     */
    public deserializeEntity(
        systemName: string,
        entityDataObservable: Observable<any>,
        entity: Entity,
        entityFields: any[]
    ): Observable<any> {
        return entityDataObservable.pipe(
            switchMap(data => {
                const entityData = data.data || data;
                const languagesObservable: Observable<any> = this._languageService.getInputLanguages();
                return languagesObservable.pipe(
                    map((languages: any) => {
                        return deserializeSimpleFields(
                            entityFields,
                            languages,
                            entity,
                            entityData
                        );
                    })
                );
            }),
            switchMap((deserializedEntity: any) => {
                return this.deserializeEntityCompoundFields(
                    entityFields,
                    [deserializedEntity],
                    systemName
                ).pipe(
                    switchMap((deserializedEntities: any) => {
                        return of(deserializedEntities[0]);
                    })
                );
            })
        );
    }

    public deserializeMainEntity(
        systemName: string,
        entityDataObservable: Observable<any>,
        fieldSystemNames?: string[],
        isDeleted?: boolean
    ): Observable<any> {
        return this._kbService.getMetaFields(systemName).pipe(
            switchMap(entityFields => {
                if (fieldSystemNames && fieldSystemNames.length > 0) {
                    entityFields = entityFields.filter(
                        (metaField: any) =>
                            fieldSystemNames.indexOf(
                                metaField.getSystemName()
                            ) !== -1
                    );
                    if (
                        entityFields.length === 1 &&
                        entityFields[0].getType() ===
                            MetaFieldType.INTEGER_INSTANCE
                    ) {
                        return entityDataObservable.pipe(
                            map(data => {
                                const value = {};
                                value[fieldSystemNames[0]] =
                                    data.data[fieldSystemNames[0]];
                                return this._entityBuilder.createEntity(
                                    MainEntity,
                                    entityFields,
                                    value,
                                    isDeleted
                                );
                            })
                        );
                    }
                }
                const entity: Entity = this._entityBuilder.createEntity<
                    MainEntity
                >(MainEntity, entityFields, null, isDeleted);
                return this.deserializeEntity(
                    systemName,
                    entityDataObservable,
                    entity,
                    entityFields
                );
            })
        );
    }

    public deserializeSubEntity(
        systemName: string,
        entityDataObservable: Observable<any>
    ): Observable<any> {
        return this._kbService.getMetaFields(systemName).pipe(
            switchMap(entityFields => {
                const entity: Entity = this._entityBuilder.createEntity<
                    MainEntity
                >(SubEntity, entityFields);
                return this.deserializeEntity(
                    systemName,
                    entityDataObservable,
                    entity,
                    entityFields
                );
            })
        );
    }

    public deserializeMainEntityWithMeta(
        systemName: string,
        entityData: any
    ): Observable<ServiceResponseDefault<Entity>> {
        const serviceResponse: ServiceResponseDefault<Entity> = new ServiceResponseDefault<
            Entity
        >();
        let entityFields: MetaField[];
        if (entityData.meta.hasViewPermission === false) {
            serviceResponse.setMeta(deserializeMeta(entityData.meta));
            serviceResponse.setData(undefined);
            serviceResponse.hasViewPermission = false;
            return of(serviceResponse);
        }

        return this._kbService.getMetaFields(systemName).pipe(
            switchMap(data => {
                entityFields = data;
                serviceResponse.setMeta(deserializeMeta(entityData.meta));
                const entity = this._entityBuilder.createEntity(
                    MainEntity,
                    entityFields
                );
                serviceResponse.setData(entity);
                const languagesObservable = this._languageService.getInputLanguages();
                return languagesObservable.pipe(
                    map(languages => {
                        return deserializeSimpleFields(
                            entityFields,
                            languages,
                            entity,
                            entityData.data
                        );
                    })
                );
            }),
            switchMap((entity: Entity) =>
                this.deserializeEntityCompoundFields(
                    entityFields,
                    [entity],
                    systemName
                )
            ),
            switchMap(() => of(serviceResponse))
        );
    }

    /**
     * Serializes the entity object.
     * @param {string} systemName - The unique identifier for the entity.
     * @param {Entity} entity - Entity object to be serialized.
     * @returns {any} the serialized entity
     */
    public serializeEntity(
        systemName: string,
        entity: Entity
    ): Observable<any> {
        let entityFields: MetaField[];
        const languages$ = this._languageService.getInputLanguages();
        const metaFields$ = this._kbService.getMetaFields(systemName);
        return zip(languages$, metaFields$).pipe(
            switchMap((data: [Language[], MetaField[]]) => {
                entityFields = data[1];
                let hasInstanceIdField = false;
                const entityFieldsObservables = this.getSerializedFieldsObservables(
                    entityFields,
                    entity,
                    data[0]
                );
                entityFields.forEach((field: MetaField) => {
                    if (field.getType() === MetaFieldType.INTEGER_INSTANCE) {
                        hasInstanceIdField = true;
                    }
                });
                let permissions$: Observable<
                    DePermission | Set<CategoryPermissionValueDto> | undefined
                >;
                if (hasInstanceIdField) {
                    const instanceId = entity.getInstanceId();
                    if (instanceId && entity.getId() && entity.getId() !== -1) {
                        permissions$ = this._dePermissionService.getCategoryDePermissionsByInstance(
                            systemName,
                            instanceId
                        );
                    } else {
                        permissions$ = this._dePermissionService.getPermissionValue(
                            systemName,
                            PermissionType.ADD
                        );
                    }
                } else {
                    permissions$ = of(undefined);
                }
                return zip(
                    combineLatest(...entityFieldsObservables).pipe(first()),
                    permissions$
                );
            }),
            map(
                (
                    data: [
                        any[],
                        (
                            | DePermission
                            | Set<CategoryPermissionValueDto>
                            | undefined
                        )
                    ]
                ) => {
                    return this.makeEntityData(entityFields, data[0], data[1]);
                }
            )
        );
    }

    /**
     * Serializes all the entity objects
     * @param {string} systemName - The unique identifier for the entity.
     * @param {Entity} entities - Entity objects to be serialized.
     * @param {boolean} create - whether this is for create operation or not
     * @returns {Observable<any[]>} the serialized entity
     */
    public bulkSerializeEntities(
        systemName: string,
        entities: Entity[],
        create: boolean
    ): Observable<any[]> {
        let entityFields: MetaField[];
        const languages$ = this._languageService.getInputLanguages();
        const metaFields$ = this._kbService.getMetaFields(systemName);
        const permissions$ = this.getEntitiesPermissions(
            systemName,
            entities,
            create
        );
        return zip(languages$, metaFields$, permissions$).pipe(
            switchMap(
                (
                    data: [
                        Language[],
                        MetaField[],
                        (DePermission | Set<CategoryPermissionValueDto>)[]
                    ]
                ) => {
                    entityFields = data[1];
                    const fieldsAndPermissions = entities.map(
                        (entity: Entity, index: number) => {
                            const entityFieldsObservables = this.getSerializedFieldsObservables(
                                entityFields,
                                entity,
                                data[0]
                            );
                            return zip(
                                combineLatest(...entityFieldsObservables).pipe(
                                    first()
                                ),
                                of(create ? data[2][0] : data[2][index])
                            );
                        }
                    );
                    return zip(...fieldsAndPermissions);
                }
            ),
            map(
                (
                    data: [
                        any[],
                        DePermission | Set<CategoryPermissionValueDto>
                    ][]
                ) => {
                    return data.map(fieldsAndPermissions =>
                        this.makeEntityData(
                            entityFields,
                            fieldsAndPermissions[0],
                            fieldsAndPermissions[1]
                        )
                    );
                }
            )
        );
    }

    /**
     * Serializes the list of SubEntity objects.
     * @param {string} systemName - The unique identifier for the subEntity.
     * @param {Array<SubEntity>} subEntities - List of SubEntities to be serialized.
     * @returns {any} the serialized subEntities
     */
    private serializeSubEntities(
        systemName: string,
        subEntities: SubEntity[]
    ): Observable<any> {
        const subEntitiesObservables = subEntities.map(
            (subEntity: SubEntity) => {
                return this.serializeEntity(systemName, subEntity);
            }
        );
        return zip(...subEntitiesObservables);
    }

    private deserializeSubEntities(
        systemName: string,
        entitiesData: any[]
    ): Observable<Entity[]> {
        const entityFieldsObservable = this._kbService.getMetaFields(
            systemName
        );
        const languagesObservable = this._languageService.getInputLanguages();
        return zip(entityFieldsObservable, languagesObservable).pipe(
            switchMap((data: any) => {
                const entityFields = data[0];
                const languages = data[1];
                const partiallyDeserializedEntities: Entity[] = entitiesData.map(
                    (entityData: any) => {
                        const entity: Entity = this._entityBuilder.createEntity<
                            SubEntity
                        >(SubEntity, entityFields);
                        return deserializeSimpleFields(
                            entityFields,
                            languages,
                            entity,
                            entityData
                        );
                    }
                );
                return this.deserializeEntityCompoundFields(
                    entityFields,
                    partiallyDeserializedEntities,
                    systemName
                );
            })
        );
    }

    private deserializeEntityCompoundFields(
        entityFields: MetaField[],
        entities: Entity[],
        categorySystemName?: string
    ): Observable<Entity[]> {
        const entityCompoundFields = entityFields
            .filter(isCompoundField)
            .filter(field => {
                return entities.some((entity: Entity) => {
                    return isFieldValueNotEmpty(field, entity);
                });
            });
        if (entityCompoundFields.length > 0) {
            const entityNonSimpleFieldObservables = entityCompoundFields.map(
                (field: MetaField) => {
                    return this.deserializeCompoundField(
                        field,
                        entities,
                        categorySystemName
                    );
                }
            );
            return zip(...entityNonSimpleFieldObservables).pipe(
                map(fieldValues => {
                    entityCompoundFields.forEach(
                        (field: MetaField, index: number) => {
                            entities.forEach(
                                (entity: Entity, entityIndex: number) => {
                                    if (fieldValues[index][entityIndex]) {
                                        entities[entityIndex].getProperty(
                                            field.getSystemName()
                                        ).value =
                                            fieldValues[index][entityIndex];
                                    } else {
                                        console.log(`There is no such compound field with id:
                ${
                    entities[entityIndex].getProperty(field.getSystemName())
                        .value
                }`);
                                        entities[entityIndex].getProperty(
                                            field.getSystemName()
                                        ).value = null;
                                    }
                                }
                            );
                        }
                    );
                    return entities;
                })
            );
        } else {
            return of(entities);
        }
    }

    private deserializeCompoundField(
        field: MetaField,
        entities: Entity[],
        categorySystemName?: string
    ): Observable<any[]> {
        const fieldType: MetaFieldType = field.getType();
        switch (fieldType) {
            case MetaFieldType.CLASSIFIER:
            case MetaFieldType.WORKFLOW_STATE: {
                const classifierIds: number[] = entities.map(
                    (entity: Entity) => {
                        return entity.getProperty<number>(field.getSystemName())
                            .value;
                    }
                );
                return this._classifierService
                    .loadClassifiersByIds(
                        field.getCompoundCategorySystemName(),
                        classifierIds.filter(this.uniqueFilter),
                        true
                    )
                    .pipe(
                        switchMap(
                            (classifiersResponse: ClassifiersResponse) => {
                                const classifiersMap: Map<
                                    number,
                                    Classifier
                                > = new Map<number, Classifier>();
                                classifiersResponse
                                    .getData()
                                    .forEach((classifier: Classifier) => {
                                        classifiersMap.set(
                                            classifier.getId(),
                                            classifier
                                        );
                                    });

                                return of(
                                    classifierIds.map(
                                        (classifierId: number) => {
                                            return classifiersMap.get(
                                                classifierId
                                            );
                                        }
                                    )
                                );
                            }
                        )
                    );
            }
            case MetaFieldType.MULTI_SELECT: {
                let classifierIds: number[] = [];
                entities.forEach((entity: Entity) =>
                    classifierIds.push(
                        ...entity.getProperty<number[]>(field.getSystemName())
                            .value
                    )
                );
                // leave only unique entries to load less data
                classifierIds = classifierIds.filter(
                    (classifierId: number, index: number) =>
                        classifierIds.indexOf(classifierId) === index
                );
                return this._classifierService
                    .loadClassifiersByIds(
                        field.getCompoundCategorySystemName(),
                        classifierIds,
                        true
                    )
                    .pipe(
                        map((classifiersResponse: ClassifiersResponse) => {
                            const classifiers: Classifier[] = classifiersResponse.getData();
                            return entities.map((entity: Entity) =>
                                entity
                                    .getProperty<number[]>(
                                        field.getSystemName()
                                    )
                                    .value.map(
                                        (classifierId: number) =>
                                            classifiers
                                                .find(
                                                    (classifier: Classifier) =>
                                                        classifier.getId() ===
                                                        classifierId
                                                )
                                                .clone() // each entity should have it's own instance of all it's classifiers
                                    )
                            );
                        })
                    );
            }
            case MetaFieldType.MAIN_ENTITY: {
                return zip(
                    ...entities.map((entity: Entity) => {
                        return this._kbService
                            .getMainEntityMetaFields(
                                categorySystemName,
                                field.getSystemName()
                            )
                            .pipe(
                                switchMap((metafields: MetaField[]) => {
                                    const entityInstanceId = entity.getProperty<
                                        number
                                    >(field.getSystemName()).value;
                                    if (!entityInstanceId) {
                                        return of(null);
                                    }
                                    if (
                                        metafields.length === 1 &&
                                        metafields[0].getType() ===
                                            MetaFieldType.INTEGER_INSTANCE
                                    ) {
                                        const value = {};
                                        value[
                                            metafields[0].getSystemName()
                                        ] = entityInstanceId;
                                        return of(
                                            this._entityBuilder.createEntity(
                                                MainEntity,
                                                metafields,
                                                value
                                            )
                                        );
                                    }
                                    return this._deService
                                        .loadEntityByInstanceId(
                                            field.getCompoundCategorySystemName(),
                                            entityInstanceId,
                                            undefined,
                                            metafields.map(metafield =>
                                                metafield.getSystemName()
                                            )
                                        )
                                        .pipe(
                                            catchError(err => {
                                                if (err.status == 404) {
                                                    const deletedEntity$ = this._auditService.getEntityLastVersion(
                                                        field.getCompoundCategorySystemName(),
                                                        entityInstanceId
                                                    );
                                                    return this.deserializeMainEntity(
                                                        field.getCompoundCategorySystemName(),
                                                        deletedEntity$,
                                                        null,
                                                        true
                                                    );
                                                } else {
                                                    return _throw(err);
                                                }
                                            })
                                        );
                                })
                            );
                    })
                );
            }
            case MetaFieldType.SUB_ENTITY: {
                return zip(
                    ...entities.map((entity: Entity) => {
                        return this.deserializeSubEntities(
                            field.getCompoundCategorySystemName(),
                            entity.getProperty<any[]>(field.getSystemName())
                                .value
                        );
                    })
                );
            }
            case MetaFieldType.LOOKUP: {
                const lookupCategorySystemName = field.getCompoundCategorySystemName();
                return this._kbService.getMetaFields(categorySystemName).pipe(
                    switchMap((metaFields: MetaField[]) =>
                        zip(
                            ...entities.map((entity: Entity) => {
                                const lookupId = entity.getProperty<number>(
                                    field.getSystemName()
                                ).value;
                                if (!lookupId) {
                                    return of(undefined);
                                }
                                return this.extractLookupFromEntity(
                                    lookupCategorySystemName,
                                    lookupId,
                                    entity,
                                    metaFields
                                );
                            })
                        )
                    )
                );
            }
            default: {
                throw new Error(
                    `Illegal field type ${field.getType()} was provided.`
                );
            }
        }
    }

    private getEntitiesPermissions(
        systemName: string,
        entities: Entity[],
        create: boolean
    ): Observable<(DePermission | Set<CategoryPermissionValueDto>)[]> {
        if (create) {
            return this._dePermissionService
                .getPermissionValue(systemName, PermissionType.ADD)
                .pipe(
                    map((permissions: Set<CategoryPermissionValueDto>) => [
                        permissions,
                    ])
                );
        }
        const instanceIds: number[] = [];
        let biggestInstanceId = -1;
        entities.forEach(entity => {
            const instanceId = entity.getInstanceId();
            instanceIds.push(instanceId);
            if (instanceId > biggestInstanceId) {
                biggestInstanceId = instanceId;
            }
        });
        const instanceIdsPerRequest =
            this.MAX_URL_LENGTH / (biggestInstanceId.toString().length + 1);
        if (entities.length <= instanceIdsPerRequest) {
            return this._dePermissionService.getCategoryDePermissionsByInstances(
                systemName,
                instanceIds
            );
        } else {
            const permissionsArray: Observable<DePermission[]>[] = [];
            let fromIndex = 0;
            let toIndex = instanceIdsPerRequest;
            while (toIndex < instanceIds.length) {
                permissionsArray.push(
                    this._dePermissionService.getCategoryDePermissionsByInstances(
                        systemName,
                        instanceIds.slice(fromIndex, toIndex)
                    )
                );
                fromIndex = toIndex;
                toIndex += instanceIdsPerRequest;
            }
            permissionsArray.push(
                this._dePermissionService.getCategoryDePermissionsByInstances(
                    systemName,
                    instanceIds.slice(fromIndex, instanceIds.length)
                )
            );
            return zip(...permissionsArray).pipe(
                map((permissionsArrays: DePermission[][]) =>
                    [].concat.apply([], permissionsArrays)
                )
            );
        }
    }

    private getSerializedFieldsObservables(
        entityFields: MetaField[],
        entity: Entity,
        languages: Language[]
    ): Observable<any>[] {
        return entityFields.map((field: MetaField) => {
            const fieldName: string = field.getSystemName();
            const fieldType: MetaFieldType = field.getType();
            const fieldValue: any = entity.getProperty(fieldName).value;
            if (fieldType === MetaFieldType.SUB_ENTITY) {
                return fieldValue.length === 0
                    ? of(fieldValue)
                    : this.serializeSubEntities(
                          field.getCompoundCategorySystemName(),
                          fieldValue
                      );
            } else if (fieldType === MetaFieldType.MAIN_ENTITY) {
                return fieldValue === null
                    ? of(null)
                    : of(fieldValue.getInstanceId());
            } else {
                return of(serializeSimpleField(field, fieldValue, languages));
            }
        });
    }

    private makeEntityData(
        entityFields: MetaField[],
        entityFieldsData: any[],
        permissions?: DePermission | Set<CategoryPermissionValueDto>
    ): object {
        const entityData: object = {};
        if (!permissions) {
            this.addAllFields(entityFields, entityFieldsData, entityData);
        } else if (permissions instanceof DePermission) {
            this.addPermittedFields(
                entityFields,
                entityFieldsData,
                permissions.viewableFields,
                entityData
            );
        } else {
            permissions.forEach(categoryPermissions => {
                if (categoryPermissions.access === Access.FULL) {
                    this.addAllFields(
                        entityFields,
                        entityFieldsData,
                        entityData
                    );
                } else if (categoryPermissions.access === Access.CUSTOM) {
                    const permissibleFields: string[] = Array.from(
                        categoryPermissions.customValues.fields.values()
                    ).map((fieldName: String) => fieldName.toString());
                    this.addPermittedFields(
                        entityFields,
                        entityFieldsData,
                        permissibleFields,
                        entityData
                    );
                }
            });
        }
        if (Object.keys(entityData).length === 0) {
            const error = new Error('No permission for the entity');
            error['status'] = 403;
            throw error;
        }
        return entityData;
    }

    private addAllFields(
        entityFields: MetaField[],
        entityFieldsData: any[],
        entityData: object
    ): void {
        entityFields.forEach((field: MetaField, index: number) => {
            entityData[field.getSystemName()] = entityFieldsData[index];
        });
    }

    private addPermittedFields(
        entityFields: MetaField[],
        entityFieldsData: any[],
        permittedFieldNames: string[],
        entityData: object
    ): void {
        entityFields.forEach((field: MetaField, index: number) => {
            if (
                permittedFieldNames.indexOf(
                    field.getMetaFieldId().getSystemName()
                ) !== -1
            ) {
                entityData[field.getSystemName()] = entityFieldsData[index];
            } else if (field.getType() === MetaFieldType.SUB_ENTITY) {
                // All sub entities should always be present at least as empty arrays
                entityData[field.getSystemName()] = [];
            } else if (field.getType() === MetaFieldType.MULTILINGUAL_STRING) {
                // All multilingual strings should always be present at least as empty objects
                entityData[field.getSystemName()] = {};
            }
        });
    }

    private extractLookupFromEntity(
        lookupCategorySystemName: string,
        lookupId: number,
        entity: Entity,
        metaFields: MetaField[]
    ): Observable<Entity> {
        const lookupMetaField = metaFields.find(
            (metaField: MetaField) =>
                metaField.getSystemName() === lookupCategorySystemName
        );
        if (lookupMetaField) {
            const lookupSubEntities = entity.getProperty<any[]>(
                lookupCategorySystemName
            ).value;
            if (!lookupSubEntities || lookupSubEntities.length === 0) {
                this.throwNoLookupError(lookupCategorySystemName, lookupId);
            }
            return this.deserializeSubEntities(
                lookupCategorySystemName,
                lookupSubEntities
            ).pipe(
                map((subEntities: Entity[]) => {
                    const lookupEntity = subEntities.find(
                        subEntity => subEntity.getId() === lookupId
                    );
                    if (!lookupEntity) {
                        this.throwNoLookupError(
                            lookupCategorySystemName,
                            lookupId
                        );
                    }
                    return lookupEntity;
                })
            );
        }
        return this.findLookupInSubEntities(
            lookupCategorySystemName,
            lookupId,
            entity,
            metaFields
        ).pipe(
            map((lookupEntity: Entity) => {
                if (!lookupEntity) {
                    this.throwNoLookupError(lookupCategorySystemName, lookupId);
                }
                return lookupEntity;
            })
        );
    }

    private findLookupInSubEntities(
        lookupCategorySystemName: string,
        lookupId: number,
        entity: Entity,
        metaFields: MetaField[]
    ): Observable<Entity> {
        const subEntitiesWithFieldsObservables: Observable<
            [MetaField[], Entity[]]
        >[] = metaFields
            .map((metaField: MetaField) => {
                if (metaField.getType() !== MetaFieldType.SUB_ENTITY) {
                    return undefined;
                }
                const subEntities = entity.getProperty<any[]>(
                    metaField.getSystemName()
                ).value;
                if (!subEntities || subEntities.length === 0) {
                    return undefined;
                }
                const subEntitySystemName = metaField.getCompoundCategorySystemName();
                return zip(
                    this._kbService.getMetaFields(subEntitySystemName),
                    this.deserializeSubEntities(
                        subEntitySystemName,
                        subEntities
                    )
                );
            })
            .filter(
                subEntitiesWithFields => subEntitiesWithFields !== undefined
            );
        if (subEntitiesWithFieldsObservables.length === 0) {
            return of(undefined);
        }
        return zip(...subEntitiesWithFieldsObservables).pipe(
            switchMap((data: [MetaField[], Entity[]][]) => {
                const subEntitiesWithLookup: [
                    MetaField[],
                    Entity[]
                ] = data.find(
                    (subEntitiesWithFields: [MetaField[], Entity[]]) => {
                        const lookupMetaField = subEntitiesWithFields[0].find(
                            (metaField: MetaField) =>
                                metaField.getSystemName() ===
                                lookupCategorySystemName
                        );
                        return lookupMetaField !== undefined;
                    }
                );
                if (subEntitiesWithLookup) {
                    return of(
                        subEntitiesWithLookup[1].find(
                            (subEntity: Entity) =>
                                subEntity.getId() === lookupId
                        )
                    );
                }
                const dataToLookups: Observable<Entity>[] = data.map(
                    (subEntitiesWithFields: [MetaField[], Entity[]]) => {
                        const hasSubEntities =
                            subEntitiesWithFields[0].find(
                                (metaField: MetaField) =>
                                    metaField.getType() ===
                                    MetaFieldType.SUB_ENTITY
                            ) !== undefined;
                        if (!hasSubEntities) {
                            return of(undefined);
                        }
                        const subEntitiesToLookups: Observable<
                            Entity
                        >[] = subEntitiesWithFields[1].map(subEntity =>
                            this.findLookupInSubEntities(
                                lookupCategorySystemName,
                                lookupId,
                                subEntity,
                                subEntitiesWithFields[0]
                            )
                        );
                        return zip(...subEntitiesToLookups).pipe(
                            map((lookupEntities: Entity[]) =>
                                lookupEntities.find(
                                    lookupEntity => lookupEntity !== undefined
                                )
                            )
                        );
                    }
                );
                return zip(...dataToLookups).pipe(
                    map(lookupEntities =>
                        lookupEntities.find(
                            lookupEntity => lookupEntity !== undefined
                        )
                    )
                );
            })
        );
    }

    private throwNoLookupError(
        lookupCategorySystemName: string,
        lookupId: number
    ): void {
        throw new Error(
            `No lookup sub entity found with ${lookupId} id and ${lookupCategorySystemName} category`
        );
    }

    private uniqueFilter(
        value: number,
        index: number,
        self: number[]
    ): boolean {
        return self.indexOf(value) === index;
    }
}

/**
 * Created by Anushavan.Hovsepyan
 * on 9/28/2016.
 */
import {switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {
    PreconditionCheck,
    StringTemplate,
} from '@synisys/idm-common-util-frontend';
import {
    PagingOptions,
    SearchParam,
    SortingOptions,
    SortType,
} from '@synisys/idm-de-core-frontend';
import {KbService, MetaField} from '@synisys/idm-kb-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {CategoryPermissionType} from '@synisys/idm-um-permission-client-js';

import {DataService, PortfolioService} from '../../api/service';
import {PortfolioSerializationService} from './portfolio-serialization.service';
import {ServiceResponse} from '../../api/model';
import {EntitySearchItem} from '../../../controls/entity-search/model';
import {from} from 'rxjs/observable/from';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class HttpPortfolioService implements PortfolioService {
    public PORTFOLIO_SERVICE_URI_KEY = 'entity-search-service-url';

    public URL_ENTITY_LOAD_PORTFOLIO_ITEMS: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/entities/${'systemName'}`;

    private _portfolioSerializationService: PortfolioSerializationService;

    public constructor(
        private _dataService: DataService,
        private _knowledgeService: KbService,
        private _languageService: LanguageService,
        private _classifierService: ClassifierService,
        private _currentLanguageProvider: CurrentLanguageProvider,
        private _applicationPropertiesService: ApplicationPropertiesService
    ) {
        this._portfolioSerializationService = new PortfolioSerializationService(
            _knowledgeService,
            _languageService,
            _classifierService,
            this
        );
    }

    /**
     * Loads portfolio items.
     * @param {string} systemName - Category System Name
     * @param {PagingOptions} [paging] - The paging to serve items in accordance.
     * @param {SortingOptions} [sorting] - The sorting to serve items in accordance.
     * @param {any} [filters]
     * @param {Array<CategoryPermissionType>} categoryPermissionTypes - Category Permission Types to serve in accordance
     * default value for is VIEW
     * @returns {Observable<any>}
     */
    public loadPortfolioItems(
        systemName: string,
        paging?: PagingOptions,
        sorting?: SortingOptions,
        filters?: any,
        categoryPermissionTypes = [CategoryPermissionType.EDIT],
        compoundMainEntityfields?: MetaField[]
    ): Observable<any> {
        PreconditionCheck.notNull(systemName);

        return from(
            this._applicationPropertiesService.getProperty(
                this.PORTFOLIO_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                const url: string = this.URL_ENTITY_LOAD_PORTFOLIO_ITEMS.replaceTemplate(
                    {
                        serviceUri,
                        systemName,
                    }
                );
                const portfolioDataObservable = this._currentLanguageProvider
                    .getCurrentLanguage()
                    .pipe(
                        switchMap((language: any) => {
                            const queryParams: SearchParam<any>[] = [];
                            queryParams.push(
                                new SearchParam('languageId', language.getId())
                            );

                            if (paging !== undefined && paging !== null) {
                                queryParams.push(
                                    new SearchParam('paging', paging)
                                );
                            }
                            if (sorting !== undefined && sorting !== null) {
                                queryParams.push(
                                    new SearchParam(
                                        'sortField',
                                        sorting.sortField
                                    )
                                );
                                queryParams.push(
                                    new SearchParam(
                                        'sortOrder',
                                        sorting.sortType === SortType.ASC
                                            ? 'ASC'
                                            : 'DESC'
                                    )
                                );
                            }
                            if (
                                categoryPermissionTypes !== undefined &&
                                categoryPermissionTypes !== null &&
                                categoryPermissionTypes.length !== 0
                            ) {
                                const categoryPermissionTypeNames: string[] = categoryPermissionTypes.map(
                                    categoryPermissionType =>
                                        CategoryPermissionType[
                                            categoryPermissionType
                                        ]
                                );
                                queryParams.push(
                                    new SearchParam(
                                        'categoryPermissionTypes',
                                        categoryPermissionTypeNames.toString()
                                    )
                                );
                            }
                            queryParams.push(
                                new SearchParam('filter', filters || {})
                            );
                            return this._dataService.load(url, queryParams);
                        })
                    );
                return this._portfolioSerializationService.deserializeEntities(
                    systemName,
                    portfolioDataObservable,
                    compoundMainEntityfields,
                    categoryPermissionTypes
                );
            })
        );
    }

    /**
     * Loads portfolio items without serialization
     * @param {string} systemName - Category System Name
     * @param {string[]} filterFields
     * @param {string} nestedEntityInstanceName
     * @param {PagingOptions} [paging] - The paging to serve items in accordance.
     * @param {SortingOptions} [sorting] - The sorting to serve items in accordance.
     * @param {any} [filters]
     * @param {Array<CategoryPermissionType>} categoryPermissionTypes - Category Permission Types to serve in accordance
     * default value for is VIEW
     * @returns {Observable<any>}
     */
    public loadEntitySearchItems(
        systemName: string,
        filterFields: string[],
        nestedEntityInstanceName: string,
        paging?: PagingOptions,
        sorting?: SortingOptions,
        filters?: any,
        categoryPermissionTypes = [CategoryPermissionType.EDIT]
    ): Observable<ServiceResponse<EntitySearchItem[]>> {
        PreconditionCheck.notNull(systemName);
        let url: string;

        return from(
            this._applicationPropertiesService.getProperty(
                this.PORTFOLIO_SERVICE_URI_KEY
            )
        ).pipe(
            switchMap((serviceUri: string) => {
                url = this.URL_ENTITY_LOAD_PORTFOLIO_ITEMS.replaceTemplate({
                    serviceUri,
                    systemName,
                });
                return this._currentLanguageProvider.getCurrentLanguage();
            }),
            switchMap((language: any) => {
                const queryParams: SearchParam<any>[] = [];
                queryParams.push(
                    new SearchParam('languageId', language.getId())
                );

                if (paging !== undefined && paging !== null) {
                    queryParams.push(new SearchParam('paging', paging));
                }
                if (sorting !== undefined && sorting !== null) {
                    queryParams.push(
                        new SearchParam('sortField', sorting.sortField)
                    );
                    queryParams.push(
                        new SearchParam(
                            'sortOrder',
                            sorting.sortType === SortType.ASC ? 'ASC' : 'DESC'
                        )
                    );
                }
                if (
                    categoryPermissionTypes !== undefined &&
                    categoryPermissionTypes !== null &&
                    categoryPermissionTypes.length !== 0
                ) {
                    const categoryPermissionTypeNames: string[] = categoryPermissionTypes.map(
                        categoryPermissionType =>
                            CategoryPermissionType[categoryPermissionType]
                    );
                    queryParams.push(
                        new SearchParam(
                            'categoryPermissionTypes',
                            categoryPermissionTypeNames.toString()
                        )
                    );
                }
                queryParams.push(new SearchParam('filter', filters || {}));
                const entitySearchItemsData$ = this._dataService.load(
                    url,
                    queryParams
                );
                return this._portfolioSerializationService.deserializeEntitySearchItems(
                    entitySearchItemsData$,
                    filterFields,
                    nestedEntityInstanceName
                );
            })
        );
    }
}

import {catchError, map} from 'rxjs/operators';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {
    CookieService,
    HttpClientWrapper,
} from '@synisys/idm-authentication-client-js';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {SearchParam} from '@synisys/idm-de-core-frontend';

import {DataService} from '../../api/service';
import {handleResponseError} from '../helper';

/**
 * An implementation of {@link DataService} to work with Apollo's services which return JSON-based data.
 * @class
 * @implements {DataService}
 */
@Injectable()
export class HttpDataService implements DataService {
    /**
     * Method expected to extract appropriate data from the response in accordance to HTTP response's structure.
     * @param {Object} body - The response object.
     * @returns {any} Data extracted from the response.
     * @private
     * @static
     */
    private static _extractResponseData(body: object): object {
        return body || {};
    }

    constructor(
        private _http: HttpClientWrapper,
        private _cookieService: CookieService
    ) {
        PreconditionCheck.notNullOrUndefined(_http);
    }

    public load(
        url: string,
        searchParams?: SearchParam<any>[]
    ): Observable<any> {
        PreconditionCheck.notNullOrUndefined(url);

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let params: HttpParams = new HttpParams();

        if (Array.isArray(searchParams)) {
            searchParams.forEach((searchParam: SearchParam<any>) => {
                const value: any =
                    typeof searchParam.getValue() !== 'object'
                        ? searchParam.getValue()
                        : JSON.stringify(searchParam.getValue());

                if (searchParam.getValue() instanceof Array) {
                    const arrayValues: string[] = searchParam.getValue() as string[];
                    arrayValues.forEach(
                        item =>
                            (params = params.append(
                                searchParam.getName(),
                                item
                            ))
                    );
                } else {
                    params = params.set(searchParam.getName(), value);
                }
            });
        }

        const options = {headers, params};

        return this._http
            .get(url, options)
            .pipe(
                map(HttpDataService._extractResponseData),
                catchError(handleResponseError)
            );
    }

    public add(
        url: string,
        data: any,
        searchParams?: SearchParam<any>[]
    ): Observable<any> {
        PreconditionCheck.notNullOrUndefined(url);
        PreconditionCheck.notNullOrUndefined(data);

        const body = JSON.stringify(data);

        let params: HttpParams = new HttpParams();
        if (Array.isArray(searchParams)) {
            searchParams.forEach((searchParam: SearchParam<any>) => {
                const value: any =
                    typeof searchParam.getValue() !== 'object'
                        ? searchParam.getValue()
                        : JSON.stringify(searchParam.getValue());
                params = params.set(searchParam.getName(), value);
            });
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const options = {headers, params};

        return this._http
            .post(url, body, options)
            .pipe(
                map(HttpDataService._extractResponseData),
                catchError(handleResponseError)
            );
    }

    public update(
        url: string,
        data: any,
        searchParams?: SearchParam<any>[]
    ): Observable<any> {
        PreconditionCheck.notNullOrUndefined(url);
        PreconditionCheck.notNullOrUndefined(data);

        const body = JSON.stringify(data);

        let params: HttpParams = new HttpParams();
        if (Array.isArray(searchParams)) {
            searchParams.forEach((searchParam: SearchParam<any>) => {
                const value: any =
                    typeof searchParam.getValue() !== 'object'
                        ? searchParam.getValue()
                        : JSON.stringify(searchParam.getValue());
                params = params.set(searchParam.getName(), value);
            });
        }
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const options = {headers, params};

        return this._http
            .put(url, body, options)
            .pipe(
                map(HttpDataService._extractResponseData),
                catchError(handleResponseError)
            );
    }

    public delete(
        url: string,
        searchParams?: SearchParam<any>[]
    ): Observable<any> {
        PreconditionCheck.notNullOrUndefined(url);

        let params: HttpParams = new HttpParams();
        if (Array.isArray(searchParams)) {
            searchParams.forEach((searchParam: SearchParam<any>) => {
                const value: any =
                    typeof searchParam.getValue() !== 'object'
                        ? searchParam.getValue()
                        : JSON.stringify(searchParam.getValue());
                params = params.set(searchParam.getName(), value);
            });
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const options = {headers, params};

        return this._http
            .delete(url, options)
            .pipe(catchError(handleResponseError));
    }

    public upload(url: string, file: File, data: any): Observable<any> {
        PreconditionCheck.notNullOrUndefined(url);
        PreconditionCheck.notNullOrUndefined(file);

        return Observable.create((observer: any) => {
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            formData.append('file', file, file.name);
            formData.append('data', JSON.stringify(data));
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader(
                'Authorization',
                'Bearer ' + this._cookieService.get('access_token')
            );
            xhr.send(formData);
        });
    }

    public download(url: string) {
        window.open(url);
    }
}

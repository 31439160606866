/**
 * @author Harut.Arakelyan on 3/15/2018
 */

import {takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy} from '@angular/core';
import {DeHeadMenuType} from './de-head-menu-type.enum';
import {ExportService, ExportType} from '@synisys/idm-export-client-js';
import './de-head-menu.component.scss';
import {AbstractDestructionSubject} from '../../shared/utilities/abstract-destruction-subject';

/**
 * Head menu item control, with exporting and downloading, online and user manual help possibilities.
 */
@Component({
    moduleId: module.id + '',
    selector: 'de-head-menu',
    templateUrl: 'de-head-menu.component.html',
})
export class DeHeadMenu extends AbstractDestructionSubject
    implements OnDestroy {
    public DeHeadMenuType = DeHeadMenuType;

    /**
     * Online help url
     * @type {string}
     */
    @Input() public onlineHelpURL: string;

    /**
     * User manual help url
     * @type {string}
     */
    @Input() public userManualHelpURL: string;

    /**
     * Category System Name.
     *
     * @type {string}
     */
    @Input() public categorySystemName: string;

    /**
     * Category Item ID.
     *
     * @type {number}
     */
    @Input() public categoryItemId: number;

    /**
     * Current Language ID.
     *
     * @type {number}
     */
    @Input() public currentLanguageId: number;

    /**
     * Enabled Export Types.
     * Default value is [ExportType.PDF, ExportType.EXCEL, ExportType.RTF].
     *
     * @type {Array<ExportType>}
     */
    @Input() public enabledMenuTypes: DeHeadMenuType[] = [
        DeHeadMenuType.EXPORT_EXCEL,
        DeHeadMenuType.EXPORT_PDF,
        DeHeadMenuType.EXPORT_RTF,
        DeHeadMenuType.HELP_MANUAL,
        DeHeadMenuType.HELP_ONLINE,
    ];
    /**
     * Layout Name where located template/css/image files.
     *
     * @type {string}
     */
    @Input() public layoutName: string;

    /**
     * Exported file name.
     * Default value is "export".
     *
     * @type {string}
     */
    @Input() public fileName = 'export';

    /**
     * Message Component Ids.
     * When we don't pass any value for this field then export service get all component messages.
     * If we don't pass any value then export service loads all component messages.
     *
     * @type {Array<number>}
     */
    @Input() public messageComponentIds: number[] = null;

    constructor(protected _exportService: ExportService) {
        super();
    }

    public onClickMenuItem(deHeadMenuType: DeHeadMenuType): void {
        switch (deHeadMenuType) {
            case DeHeadMenuType.EXPORT_EXCEL:
            case DeHeadMenuType.EXPORT_PDF:
            case DeHeadMenuType.EXPORT_RTF:
                this.export(this.getExportType(deHeadMenuType));
                break;
            case DeHeadMenuType.HELP_ONLINE:
                this.openHelp(this.onlineHelpURL);
                break;
            case DeHeadMenuType.HELP_MANUAL:
                this.openHelp(this.userManualHelpURL);
                break;
            default:
                throw new Error(`Unsupported menu type ${deHeadMenuType}`);
        }
    }

    public isEnabled(deHeadMenuType: DeHeadMenuType): boolean {
        return this.enabledMenuTypes.indexOf(deHeadMenuType) > -1;
    }

    protected export(exportType: ExportType) {
        this._exportService
            .export(
                this.categorySystemName,
                this.categoryItemId,
                this.currentLanguageId,
                exportType,
                this.layoutName,
                this.fileName,
                this.messageComponentIds
            )
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                () => {},
                err => console.error(err)
            );
    }

    protected openHelp(url: string): void {
        if (url) {
            window.open(url);
        } else {
            throw new Error('Help url is undefined');
        }
    }

    private getExportType(deHeadMenuType: DeHeadMenuType): ExportType {
        switch (deHeadMenuType) {
            case DeHeadMenuType.EXPORT_EXCEL:
                return ExportType.EXCEL;
            case DeHeadMenuType.EXPORT_PDF:
                return ExportType.PDF;
            case DeHeadMenuType.EXPORT_RTF:
                return ExportType.RTF;
            default:
                throw new Error(`Unsupported export type ${deHeadMenuType}`);
        }
    }
}

/**
 * Created by mariam.melkonyan on 2/17/17.
 */
import {Observable} from 'rxjs/Observable';

import {StringTemplate} from '@synisys/idm-common-util-frontend';
import {PagingOptions, SortingOptions} from '@synisys/idm-de-core-frontend';
import {CategoryPermissionType} from '@synisys/idm-um-permission-client-js';
import {MetaField} from '@synisys/idm-kb-service-client-js';
import {ServiceResponse} from '../../api/model/service-response';
import {EntitySearchItem} from '../../../controls/entity-search/model/entity-search-item';

export abstract class PortfolioService {
    public URL_ENTITY_LOAD_PORTFOLIO_ITEMS: StringTemplate;

    public abstract loadPortfolioItems(
        systemName: string,
        paging?: PagingOptions,
        sorting?: SortingOptions,
        filters?: any,
        categoryPermissionTypes?: CategoryPermissionType[],
        compoundMainEntityfields?: MetaField[]
    ): Observable<any>;

    public abstract loadEntitySearchItems(
        systemName: string,
        filterFields: string[],
        nestedEntityInstanceName: string,
        paging?: PagingOptions,
        sorting?: SortingOptions,
        filters?: any,
        categoryPermissionTypes?: CategoryPermissionType[]
    ): Observable<ServiceResponse<EntitySearchItem[]>>;
}

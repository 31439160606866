/**
 * @author siranush.avetisyan
 * @since 8/28/17.
 */
import {map, catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Params,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {Observable} from 'rxjs/Observable';

import {
    AuthenticationService,
    UserData,
} from '@synisys/idm-authentication-client-js';
import {StringTemplate} from '@synisys/idm-common-util-frontend';
import {LockData, LockingService} from '@synisys/idm-lock-service-client-js';

import {DeService} from '../api/service';
import {MainEntity} from '../impl/model';
import {zip} from 'rxjs/observable/zip';
import {of} from 'rxjs/observable/of';

/**
 * DE Lock Guard for locking DE and navigating to error page.
 */
@Injectable()
export class LockGuard implements CanActivate {
    public LOCK_ERROR_URL: StringTemplate = StringTemplate.createTemplate`${'lockErrorPage'}/${'entityInstanceId'}/${'userId'}`;

    constructor(
        private _deService: DeService,
        private _router: Router,
        private _lockService: LockingService,
        private _authenticationService: AuthenticationService
    ) {}

    /**
     * This method check if DE already locked by another user then navigating to lock error page,
     * otherwise add lock for DE.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<boolean>}
     */
    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        const entityInstanceId: number = route.params.instanceId;
        const lockField: string = route.data.lockField;
        const systemName: string = route.data.systemName;
        const lockErrorPage: string = route.data.lockErrorPage;
        const queryParams: Params = route.data.queryParams;

        const mainEntity$: Observable<any> = this._deService.loadEntityByInstanceId(
            systemName,
            entityInstanceId
        );
        const userData$: Observable<UserData> = this._authenticationService.getUserData();
        const lockData$: Observable<LockData> = this._lockService.getLock(
            systemName,
            entityInstanceId
        );

        return zip(userData$, mainEntity$).pipe(
            switchMap(data => {
                const userData: UserData = data[0];
                const mainEntity: MainEntity = data[1];

                return lockData$.pipe(
                    switchMap((lockData: LockData) => {
                        const currentUserId: number = +userData.userId;
                        if (
                            lockData.getIsLocked() &&
                            lockData.userId !== currentUserId
                        ) {
                            const url: string = this.LOCK_ERROR_URL.replaceTemplate(
                                {
                                    entityInstanceId: entityInstanceId.toString(),
                                    lockErrorPage,
                                    userId: lockData.userId.toString(),
                                }
                            );
                            this._router.navigateByUrl(url);

                            return of(false);
                        } else if (
                            lockData.getIsLocked() &&
                            lockData.userId === currentUserId
                        ) {
                            // no need for add lock if the current user is same as locker user
                            return of(true);
                        } else {
                            return this._lockService
                                .addLock(
                                    systemName,
                                    entityInstanceId,
                                    mainEntity.getProperty<string>(lockField)
                                        .value
                                )
                                .pipe(
                                    map(() => {
                                        return true;
                                    })
                                );
                        }
                    }),
                    catchError(error => {
                        const url: string = this.LOCK_ERROR_URL.replaceTemplate(
                            {
                                entityInstanceId: entityInstanceId.toString(),
                                lockErrorPage,
                                userId: null,
                            }
                        );
                        if (!queryParams) {
                            this._router.navigateByUrl(url);
                        } else {
                            this._router.navigate([url], {queryParams});
                        }
                        return of(false);
                    })
                );
            })
        );
    }
}
